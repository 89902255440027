.LoadingOverlay {
    z-index: 99999;
    position: fixed;
    left: 0;
    top:0;
    width: 100%;
    height: 100%;
    background-image: url('/balloon-loading.gif');
    background-position: center center;
    background-repeat: no-repeat;
}