.HistoryItemContainer {
}

.HistoryItem {
    border-bottom: solid 1px;
}

.HistoryButton {
    background-size: 20px 20px;
    background-position: 5px 10px;
    background-repeat: no-repeat;
    border: none;
    width: calc(100% - 30px);
    height: 40px;
    text-align: left;
    padding-left: 30px;
}

.ThemeLight .HistoryButton {
    background-image: url("images/search-history-light.png");
}

.ThemeDark .HistoryButton {
    background-image: url("images/search-history-dark.png");
}
