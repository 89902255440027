
.ModernViewBarq {

}

.ModernViewBarq .ContentFull {
    width: 100%;
}

.ModernViewBarq .ContentFull .ContentMain {
    width: calc(100% - 425px);
    display: inline-block;
    /*background: red;*/
    vertical-align: top;
}

.ModernViewBarq .ContentFull .ContentSidebar {
    position: fixed;;
    display: inline-block;
    padding-top: 40px;
    width: 375px;
    margin-left: 10px;
    /*background: green;*/
    vertical-align: top;
    height: 100%;
    overflow: hidden;
}

.ModernViewBarq .ViewSpiceList {
    display: grid !important;
    column-gap: 10px;
}

.ModernViewBarq .ViewSpiceList .ViewSpice {
    /*background: red;*/
}

.ModernViewBarq .ViewSpiceList .ViewSpice .SpiceTitle {
    margin-top: 5px;
    margin-bottom: 5px;
    vertical-align: top;
    font-size: 10px;
}

.ModernViewBarq .ViewSpiceList .ViewSpice .SpiceValue {
    vertical-align: top;
    padding: 8px 5px 8px 5px;
    border-radius: 10px;
    overflow: hidden;
    font-size: 14px;
    margin-bottom: 5px;
    text-align: center;
}

.ModernViewBarq .ViewTagList {
    display: grid !important;
    column-gap: 10px;
}

.ModernViewBarq .ViewTagList .ViewTag {
    vertical-align: top;
    padding: 8px 5px 8px 5px;
    border-radius: 10px;
    overflow: hidden;
    font-size: 14px;
    margin-bottom: 5px;
    text-align: center;
}


.ModernViewBarq .WideImageContainer {
    width: 100%;
    height: 480px;
    /*background: red;*/
}

.ModernViewBarq .WideImageContainer .WideImageLeft {
    display: inline-block;
    /*background: green;*/
    width: 640px;
    height: 480px;
    background-position: center center;
    background-repeat: no-repeat;
    overflow: hidden;
}

.ModernViewBarq .WideImageContainer .WideImageLeft .Overflow {
    display: inline-block;
    /*background: green;*/
    width: 640px;
    height: 480px;
    background-position: center center;
    background-repeat: no-repeat;
    overflow: hidden;
    position: absolute;
}

.ModernViewBarq .WideImageContainer .WideImageLeft .FullBadge {
    background-size: 320px auto;
    background-position: center center;
    background-repeat: no-repeat;
}

.ModernViewBarq .WideImageContainer .WideImageLeft .FullImage {
    background-size: 100% 100%;
    background-position: center center;
    background-repeat: no-repeat;
}

.ModernViewBarq .WideImageContainer .WideImageRight {
    vertical-align: top;
    display: inline-block;
    /*background: yellow;*/
    width: calc(100% - 640px);
}

.ModernViewBarq .WideImageContainer .WideImageRight .BarqActionsContainer {
    margin-top: 20px;
}

.ModernViewBarq .WideImageRight .CalendarData {
    padding: 20px;
    width: 300px;
    margin-left: calc(100% - 340px);

}

.ModernViewBarq .WideImageRight .Calendar {
    margin-top: 15px;
    margin-bottom: 15px;
    width: 150px;
    text-align: center;
    display: block;
    vertical-align: middle;
}

.ModernViewBarq .WideImageRight .Calendar .CalendarDay {
    font-size: 32px;
    padding-bottom: 5px;
}


.ModernViewBarq .WideImageRight .Calendar .CalendarMonth {
    padding-bottom: 5px;

}

.ModernViewBarq .WideImageRight .Calendar .CalendarTime {
    font-size: 32px;
    padding-bottom: 5px;
}

.ModernViewBarq .WideImageRight .Calendar .CalendarRemain {
}


.ModernViewBarq .LocationImages {
    width: 100%;
    display: grid !important;
}

.ModernViewBarq .LocationImages img {
    display: none;
    border-radius: 10px;
    width: 100%;
}

.ModernViewBarq .LocationDetails {
    width: 100%;
    display: grid !important;
    column-gap: 10px;
}

.ModernViewBarq .EventDetails {
    display: none;
}


.ModernViewBarq .LocationDetails .ViewLocationData,
.ModernViewBarq .EventDetails .ViewLocationData {
    margin: 5px 0 5px 0;
}


.ModernViewBarq .LocationDetails .ViewLocationDetail,
.ModernViewBarq .EventDetails .ViewLocationDetail {
    overflow: hidden;

}

.ModernViewBarq .BarqsSectionContainer {
    width: 100%;
    position: relative;
}

.SearchPosition {
    border-radius: 10px;
    border: solid 1px;
    padding: 10px;
    width: calc(100% - 22px);
    margin-top: 40px;
}

.SearchPosition .PrevButton {
    margin-top: 20px;
    margin-right: 10px;
}

.SearchPosition .grayButton {
    width: 137px;
    margin-top: 20px;
}

.SearchPosition .ButtonDisabled {
    pointer-events: none;
    cursor: default;
}

.BarqLocationImage {
    margin-bottom: 10px;
}

@media screen and (max-width: 1395px) {

    .ModernViewBarqNarrow {
        display: block;
    }

    .ModernViewBarqWide {
        display: none;
    }

}


@media screen and (min-width: 1396px) {

    .ModernViewBarqNarrow {
        display: none;
    }

    .ModernViewBarqWide {
        display: block;
    }
}

@media screen  and  (max-width: 767px) {

    .BarqItemCardSize {
        width: 160px;
        margin-right: 10px;
        margin-bottom: 8px;
    }

    .BarqItemCardContentSize {
        width: 100%;
    }

    .BarqItemCardImageTitle {
        font-size: 14px;
    }

    .BarqItemCardImageText {
        font-size: 12px;
    }


    .BarqItemCardImageTitle {
        line-height: 16px;
        height: 32px;
    }

    .BarqItemCardImageTextTime {
        line-height: 16px;
        height: 16px;
    }

    .BarqItemCardImageTextLocation {
        line-height: 16px;
        height: 16px;
    }


    .BarqItemCardAll {
        text-align: center;
        word-wrap: break-word !important;
        white-space: normal;
        margin-right: 0 !important;


        width: 80px;
        height: 174px;

        position: relative;
        border: none;
        display: inline-block;
        vertical-align: top;

    }

    .BarqItemCardAllContent {
        display: flex;
        justify-content: center;
        align-items: center;
        vertical-align: middle;
        height: calc(100% - 25px);
    }


}

@media screen  and (max-width: 500px) {


    .ModernViewBarq .EventDetails {
        width: 100%;
        display: grid !important;
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }


    .ModernViewBarq .ViewSpiceList,
    .ModernViewBarq .ViewTagList {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        column-gap: 10px;
    }

    .ModernViewBarq .LocationImages {
        grid-template-columns: repeat(3, minmax(0, 1fr));
        column-gap: 10px;
    }


    .ModernViewBarq .LocationImages img:nth-child(-n+3) {
        display: inline-block !important;
    }

    .ModernViewBarq .LocationDetails {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .ModernViewBarq .SeparateBlock {
        padding-bottom: 10px;
    }

    .ModernViewBarq .WideImageContainer {
        height: auto;
    }

    .ModernViewBarq .WideImageContainer .WideImageLeft {
        display: block;
        width: 100%;
        height: 300px;
        vertical-align: top;
    }

    .ModernViewBarq .WideImageContainer .WideImageLeft .Overflow {
        display: inline-block;
        width: calc(100% - 20px);
        height: 300px;
        background-position: center center;
        background-repeat: no-repeat;
        overflow: hidden;
        position: absolute;
    }

    .ModernViewBarq .WideImageContainer .WideImageLeft .FullBadge {
        background-size: 180px auto;
        background-position: center center;
        background-repeat: no-repeat;
    }

    .ModernViewBarq .WideImageContainer .WideImageRight {
        /*background: red !important;*/
        vertical-align: top;
        display: none;
        width: 100%;
    }

    .ModernViewBarq .ModernViewBarqActions {
        display: inline;
        width: 100%;
    }


    .ModernViewBarq .WideImageRight .CalendarData {
        display: none;
    }

    .ModernViewBarq .WideImageRight .Calendar {
        width: 120px;
    }

    .ModernViewBarq .Actions .Buttons {
        margin-top: 20px;
    }


}


@media screen  and (min-width: 501px) and (max-width: 979px) {

    .ModernViewBarq .WideImageContainer {
        height: auto;
    }

    .ModernViewBarq .WideImageContainer .WideImageLeft {
        display: block;
        width: 100%;
        height: 480px;
        vertical-align: top;
    }


    .ModernViewBarq .ModernViewBarqActions {
        display: none;
    }

    .ModernViewBarq .LocationImages,
    .ModernViewBarq .ViewSpiceList,
    .ModernViewBarq .ViewTagList {
        grid-template-columns: repeat(3, minmax(0, 1fr));
        column-gap: 10px;
    }

    .ModernViewBarq .LocationImages img:nth-child(-n+3) {
        display: inline-block !important;
    }

    .ModernViewBarq .LocationDetails {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .ModernViewBarq .SeparateBlock {
        padding-bottom: 10px;
    }

    .ModernViewBarq .WideImageContainer .WideImageRight {
        width: 100%;
        display: block;
        /*position: relative;*/
    }

    /*Common with -768px*/
    .ModernViewBarq .WideImageContainer {
        height: auto;
    }

    .ModernViewBarq .WideImageContainer .WideImageLeft {
        display: block;
        width: 100%;
        vertical-align: top;
        background-position: left;
    }


    .ModernViewBarq .WideImageContainer .WideImageRight {
        /*background: red !important;*/
        vertical-align: top;
        display: block;
        width: 100%;
        height: auto;
    }

    .ModernViewBarq .WideImageRight .CalendarData {
        vertical-align: top;
        left: 100%;
        margin-left: -120px;
        padding: 10px 0 10px 0;
        margin-top: -270px;
        margin-bottom: 139px;
        width: 120px;
        height: 131px;
        top: 0;
        position: relative;
    }

    .ModernViewBarq .WideImageRight .Calendar {
        width: 120px;
    }

    .ModernViewBarq .Actions .Buttons {
        margin-top: 20px;
    }
}


@media screen  and (min-width: 980px) and (max-width: 1439px) {

    .ModernViewBarq .LocationImages,
    .ModernViewBarq .ViewSpiceList,
    .ModernViewBarq .ViewTagList {
        grid-template-columns: repeat(5, minmax(0, 1fr));
        column-gap: 10px;
    }

    .ModernViewBarq .LocationImages img:nth-child(-n+5) {
        display: inline-block !important;
    }

    .ModernViewBarq .LocationDetails {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .ModernViewBarq .SeparateBlock {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .ModernViewBarq .ModernViewBarqActions {
        display: none;
    }
}

@media screen  and (min-width: 1440px) {

    .ModernViewBarq .LocationImages,
    .ModernViewBarq .ViewSpiceList,
    .ModernViewBarq .ViewTagList {
        grid-template-columns: repeat(5, minmax(0, 1fr));
        column-gap: 10px;
    }

    .ModernViewBarq .LocationImages img:nth-child(-n+6) {
        display: inline-block !important;
    }

    .ModernViewBarq .LocationDetails {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .ModernViewBarq .SeparateBlock {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .ModernViewBarq .ModernViewBarqActions {
        display: none;
    }
}
