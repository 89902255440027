.SelectSpiceDialogSize {
    width: 400px !important;
    max-width: 90% !important;
    min-height: 50% !important;
}

.SpicesIcon {
    width: 100%;
    height: 60px;
    background-size: 56px 40px;
    background-position: center bottom;
    background-image: url("images/barqme_dialog_icon_filter.png");
    background-repeat: no-repeat;
}

.SpiceItemContainer {
}

.SpiceItem {

}

.SpiceItem .SpiceKey {
    padding: 15px 0 5px 0;
    font-size: 10px;
}


.SpiceItem .SpiceValue {
    /*background-color: red;*/
}

.SpiceButtons {
    margin-top: 10px;
    display: grid;
    column-gap: 5px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
}




.ModernDialog .SpiceDialogSearchButton {
    width: 100%;
    margin-bottom: 0 !important;
}

.ModernDialog .SpiceButtonSeparator {
    width: 100%;
    border-bottom: solid 1px;
    height: 0px;
}


.ModernDialog .SpiceButtonValue {
    width: calc(100% - 50px);
    height: 35px;
    padding: 0 0 0 20px;
    border: 0;
    text-align: left;
    font-size: 13px;
    color: #42abc2;

    white-space: nowrap;
    line-height: 35px;
    text-overflow: ellipsis;
    overflow: hidden;


    /*border-bottom: solid 1px;*/
}

.ModernDialog .SpiceButtonExtra {
    border-radius: 20px;
    width: 30px;
    height: 30px;
    padding: 0;
    border: 0;
    text-align: center;
    font-size: 14px;
    background-size: 20px 20px;
    margin-left: 5px;
    margin-top: 5px;
    border-bottom: solid 1px;
}

.ModernDialog .SpiceButtonSearch {
    background: url("button-search-spice.png") center center no-repeat;
    background-size: 20px 20px;
}

.SearchFilter {
    width: 100%;
    height: 40px;
    /*position: absolute;;*/
}

.SearchFilter input {
    width: calc( 100% - 20px);
    height: calc( 100% - 10px);
    padding: 5px 10px 5px 10px;
    border: none;
    background: transparent;

}


.ModernDialog .SpiceButton {
    width: calc(100% - 25px);
    height: 35px;
    border: 0;
    text-align: left;
    font-size: 13px;
    color: #42abc2;
    margin: 0;
    white-space: nowrap;
    line-height: 35px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;

}

.ModernDialog .ClearSpiceButton {
    vertical-align: top;
    width: 20px;
    height: 35px;
    margin: 0;
    border: none;
    background: url("./images/button_field_clear.png") no-repeat center center;
    display: inline-block;
}


@media screen  and (max-width: 767px) {
    .SpiceItemContainer {
        padding: 0 20px 20px 20px;
        display: grid;
        column-gap: 15px;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        height: auto;
    }


}


@media screen  and (min-width: 768px)  {

    .SpiceItemContainer {
        padding: 20px;
        display: grid;
        column-gap: 15px;
        grid-template-columns: repeat(2, minmax(0, 1fr));

    }

}
