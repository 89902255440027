.MyPostList {
    margin-top: 20px;
    width: 100%;
    position: relative;
    /*left:50%;*/
}

.ShowPost {
    clear: both;
    margin-top: 20px;
    /*border: solid 1px;*/
    padding: 10px;
    display: block;
    margin-right: 10px;
}

.ShowPost .ShowPostImage {
    /*background: red;*/
    width: 90px;
    display: inline-block;
    vertical-align: top;
}

.ShowPost .ShowPostImage .MyPostProfilePicture {
    width: 80px;
    height: 80px;
    border-radius: 40px;
    background-size: 80px 80px;
}


.ShowPost .ShowPostContent {
    display: inline-block;
    width: 100%;
    max-width: 640px;
    /*background: green;*/
    vertical-align: top;

}


@media screen  and (max-width: 867px) {


    .ShowPost {
        position: relative;
        width: calc(100% - 20px);
    }

}


@media screen  and (min-width: 768px) {

    .ShowPost {
        position: relative;
        clear: both;
        left: calc(50% - 384px);
        width: 748px;
    }

}