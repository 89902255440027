
.ViewBarqActions .Buttons {
    width: 100%;
    height: 50px;
    left: 50%;
    text-align: center;
}

.ViewBarqActions .Buttons Button {
    width: 50px;
    height: 50px;
    border: none;
    margin: 0 10px 0 10px;
    background-size: 30px 30px;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: transparent;
}


.ThemeLight .LikeOff {
    background-image: url("/actions/like_off_light.png") !important;
}

.ThemeDark .LikeOff {
    background-image: url("/actions/like_off_dark.png") !important;
}

.ThemeLight .LikeOn {
    background-image: url("/actions/like_on_light.png") !important;
}

.ThemeDark .LikeOn {
    background-image: url("/actions/like_on_dark.png") !important;
}


.ThemeLight .DislikeOff {
    background-image: url("/actions/dislike_off_light.png") !important;
}

.ThemeDark .DislikeOff {
    background-image: url("/actions/dislike_off_dark.png") !important;
}

.ThemeLight .DislikeOn {
    background-image: url("/actions/dislike_on_light.png") !important;
}

.ThemeDark .DislikeOn {
    background-image: url("/actions/dislike_on_dark.png") !important;
}


.ThemeLight .FlagOff {
    background-image: url("/actions/flag_off_light.png") !important;
}

.ThemeDark .FlagOff {
    background-image: url("/actions/flag_off_dark.png") !important;
}

.ThemeLight .FlagOn {
    background-image: url("/actions/flag_on_light.png") !important;
}

.ThemeDark .FlagOn {
    background-image: url("/actions/flag_on_dark.png") !important;
}

.ThemeLight .Share {
    background-image: url("/actions/share_light.png") !important;
}

.ThemeDark .Share {
    background-image: url("/actions/share_dark.png") !important;
}
