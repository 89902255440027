
.FeatureTitle {
    width: calc(100% - 40px);
    max-width: 825px;
    transform: translateX(-50%);

    left: 50%;
    position: fixed;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 10px;
    font-weight: 400;
    font-size: 42px;
    font-family: 'Comfortaa', sans-serif;
}

.CardContainer {
    /*background-color: #F6F4F6 !important;*/
    position: relative;
    top: 0px;
    width: 100%;
}

.FeatureCard {
    position: fixed;
    width: calc(100% - 40px);
    height: 500px;
    max-width: 825px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 11px;
    border: solid 1px;

}

.FeatureCard .FeatureText {
    /*background: red;*/
    width: calc(100% - 40px);
    position: absolute;
    left: 50%;
    top: 70px;
    transform: translateX(-50%);
    font-weight: 400;
    font-size: 20px;
    font-family: 'Comfortaa', sans-serif;
    /*color: white !important;*/
    text-align: center;
}

.FeatureCard .Iphone {
    position: relative;
    left: 50%;
    transform: translate(-50%, -100%);
    top: 100%;
    /*background-image: url("iphone6-sample.png");*/
    /*mix-blend-mode: multiply;*/
}

.FeatureCard .CardBarqboard {
    background: url("feature-images/barqme-feature-barqboard.png");
}

.FeatureCard .CardSearch {
    background: url("feature-images/barqme-feature-search.png");
}

.FeatureCard .CardSpices {
    background: url("feature-images/barqme-feature-spices.png");
}

.FeatureCard .CardSubscribe {
    background: url("feature-images/barqme-feature-subsribe.png");
}

.FeatureCard .CardDiversity {
    background: url("feature-images/barqme-feature-diversity.png");
}

.FeatureCard .CardEvents {
    background: url("feature-images/barqme-feature-evnts.png");
}


@media screen  and  (max-width: 499px) {

    .FeatureTitle {

        font-size: 28px;
    }

    .FeatureCard .FeatureText {
        top: 20px;
    }

    .FeatureCard {

        height: 350px;
        /*min-height: 350px;*/
        /*height: calc(100% - 310px);*/

    }

    .FeatureCard .Iphone {
        width: 200px;
        height: 269px;
        background-size: 200px 269px;
    }
}




@media screen  and  (min-width: 500px) and (max-height: 899px) {

    .FeatureCard .FeatureText {
        top: 30px;
    }

    .FeatureCard {
        height: calc(100% - 300px);
    }

    .FeatureCard .Iphone {
        width: 280px;
        height: 377px;
    }

}


@media screen  and  (min-width: 500px) and  (min-height: 900px) {

    .FeatureCard {
        height: 500px;
    }

    .FeatureCard .Iphone {
        width: 280px;
        height: 377px;
    }

}