.SectionTitle {
    float: left;
    padding-top: 10px !important;
    padding-bottom: 15px;
    font-weight: 700;
    font-size: 24px;
    font-family: 'Comfortaa', sans-serif;
}

.SectionTitleButton {
    height: 40px;
    float: right;
    padding-bottom: 10px;
    right: 0;
    top: 0;
}

.SectionTitleClear  {
    clear: both;
}

.SectionTitleButton .SectionTitleButtonLink {
    margin-top: 10px;
    height: 30px;
    display: inline-block;
    line-height: 30px;
    font-weight: bold;
    font-size: 10px;
    background: #E9F5F8;
    /*border: solid 1px #42abc2;*/
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 8px;

}
