@import url('https://fonts.googleapis.com/css?family=Roboto:300,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@400;700&display=swap');

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    scrollbar-width: thin;

}

.NoScroll {
    overflow: hidden;
}


input[type=text] {
    /* Remove First */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

a, a:visited, a:hover {
    /*color: #42abc2;*/
    text-decoration: none;
    cursor: pointer;
}

h1 {
    padding-top: 10px !important;
    padding-bottom: 15px;
    font-family: 'Comfortaa', sans-serif;
    font-weight: bold;
    font-size: 24px;
    text-align: left !important;
}

h2 {
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 24px;
    padding: 25px 0 5px;
    margin: 0;
    text-align: left !important;
}

h3 {
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 18px;
    padding: 15px 0 5px;
    margin: 0;
    text-align: left !important;
}

.barqButton,  .barqButton:hover, .barqButton:visited , .largeBarqButton, .largeBarqButton:hover, .largeBarqButton:visited {
     display: inline-block;
     padding: 10px 16px 10px 16px !important;
     width: auto;
     border: solid 1px #42abc2;
     background: #42abc2;
     color: white;
     text-align: center;
     margin-bottom: 10px;
 }

.redButton,  .redButton:hover, .redButton:visited , .largeRedButton, .largeRedButton:hover, .largeRedButton:visited {
    display: inline-block;
    padding: 10px 16px 10px 16px !important;
    width: auto;
    border: solid 1px #E55151;
    background: #E55151;
    color: white;
    text-align: center;
    margin-bottom: 10px;
}

a.web_link, a.web_link:visited {
    border: none;
    display: inline-block;
    color: #42abc2;
    margin: 0 0 10px;
    padding-left: 22px;
    background: transparent url("/barqme-link.png") 5px 5px no-repeat;
    background-size: 10px 10px;
}

a.web_link:hover {
    display: inline-block;
    color: #E55151;
    margin: 0 0 10px;
    background: url("/barqme-link-hover.png") 5px 5px no-repeat;
    background-size: 10px 10px;
}

.transparentButton {
    border: none;
    display: inline-block;
    color: #42abc2;
    padding: 5px;
    background: transparent !important;
}


.infoButton {
    display: inline-block;
    width: 100%;
    border-top: solid 2px;
    border-bottom: solid 2px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    font-weight: bold;
    font-size: 10px;
}


.grayButton, .grayButton:hover, .grayButton:visited, .grayButton:active,
.barqFrameButton, .barqFrameButton:hover, .barqFrameButton:visited, .barqFrameButton:active,
.redFrameButton, .redFrameButton:hover, .redFrameButton:visited, .redFrameButton:active {
    /*margin-top: 10px;*/
    display: inline-block;
    padding: 10px 16px 10px 16px !important;
    width: auto;
    border: solid 1px;
    text-align: center;
    margin-bottom: 10px;
}


.grayButtonSmall, .grayButtonSmall:hover, .grayButtonSmall:visited, .grayButtonSmall:active {
    /*margin-top: 10px;*/
    display: inline-block;
    padding: 4px 8px 4px 8px !important;
    width: auto;
    border: solid 1px;
    text-align: center;
    margin-bottom: 10px;
}

.barqButton, .barqButton:hover, .barqButton:visited {
    display: inline-block;
    padding: 10px 16px 10px 16px;
    width: auto;
    border: none;
    background: #42abc2;
    color: white !important;
    text-align: center;
    margin-bottom: 10px;
}

.barqButton:hover {
    background-color: #316E87;
}


.oneLineButton {
    margin-right: 10px;
}

.MarginTop {
    margin-top: 20px;
}

.MarginBottom {
    margin-bottom: 20px;
}

.InlineDialog {
    border: solid 1px;
    padding: 20px 20px 10px 20px;
    margin-bottom: 20px;
}

.BorderButton {
    margin-top: 20px;
    height: 50px;
    border: solid 1px #42abc2;
    color: #42abc2;
    padding-left: 20px;
    padding-right: 20px;
    text-transform: uppercase;
    background: transparent;
}

.HandCursor {
    cursor: pointer;
}