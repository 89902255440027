@media screen  and (max-width: 499px) {

    body {
        /*Banner*/
        padding: 0px 0 0;
    }

    .RootContainer {
        width: 100%;
    }

    .RootContainerNoSidebar {
        width: 100%;
    }

    .SidebarWidth {
        width: 250px;
        display: block;
    }

    .SectionWidthContainer {
        vertical-align: top;
    }


    .SectionWidthHalfSize {
        width: calc(100% - 40px);
    }

    .ArticleMediaHeightSize {
        height: 98px;
        background-size: 500px 98px;
    }

    .ArticleMediaHeightDoubleSize {
        height: 200px;
        background-size: 500px 200px;
    }

    .ArticleMediaHeightTripleSize {
        height: 300px;
        background-size: 500px 300px;
    }

    .ShowOnMobileOnly {
        display: inline-block;
    }

    .HideOnMobile {
        display: none !important;
    }

    .PaddingOnMobile {
        padding-left: 20px;
        padding-right: 20px;
    }

    .SearchFieldSizeWidth {
        width: calc(100% - 20px);
        margin-left: 10px;
    }

    .ShowOnlySmall {
        display: inline;
    }

    .ShowOnlyMedium {
        display: none;
    }

    .ShowOnlyLarge {
        display: none;
    }


    .TextSizeLarge {
        font-size: 16px !important;
    }

    .TextSizeLargePadding {
        padding: 10px !important;
    }

    .ButtonFull {
        width: 100% !important;
    }

    .Size50Left {
        width: calc(100% - 30px);
        padding-left: 20px;
        padding-right: 20px;
        background: red;
        vertical-align: top;
        float: left;
        clear: top;
    }

    .Size50Right {
        width: calc(100% - 30px);
        padding-left: 20px;
        padding-right: 20px;
    }


    .SectionWidthContainer {
        width: 100%;
        vertical-align: top;
    }

    .PaddingDependsOnWidth {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }

    .AntiPaddingDependsOnWidth {
    }




}

@media screen and (min-width: 500px) and (max-width: 767px) {

    body {
        /*Banner*/
        padding: 0px 0 0;
    }

    .RootContainer {
        width: 100%;
    }

    .RootContainerNoSidebar {
        width: 100%;
    }

    .SidebarWidth {
        width: 250px;
        display: block;
    }

    .SectionWidthContainer {
        width: 100%;
        vertical-align: top;
    }

    .PaddingDependsOnWidth {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }

    .AntiPaddingDependsOnWidth {
    }



    .SectionWidthHalfSize {
        width: calc(100% - 40px);
    }


    .ArticleMediaHeightSize {
        height: 150px;
        background-size: 788px 150px;
    }

    .ArticleMediaHeightDoubleSize {
        height: 300px;
        background-size: 788px 300px;
    }

    .ArticleMediaHeightTripleSize {
        height: 450px;
        background-size: 788px 450px;
    }

    .ShowOnMobileOnly {
        display: inline-block;
    }

    .HideOnMobile {
        display: none !important;
    }

    .PaddingOnMobile {
        padding-left: 20px;
        padding-right: 20px;
    }

    .SearchFieldSizeWidth {
        width: 100%;
        margin-left: 20px;
    }

    .ShowOnlySmall {
        display: none;
    }

    .ShowOnlyMedium {
        display: inline;
    }

    .ShowOnlyLarge {
        display: none;
    }

    .TextSizeLarge {
        font-size: 16px !important;
    }


    .TextSizeLargePadding {
        padding: 10px !important;
    }

    .ButtonFull {
        position: relative;
        width: 50% !important;
        left: 50%;
        margin-left: -25%;
    }


    .Size50Left {
        width: calc(100% - 30px);
        padding-left: 20px;
        padding-right: 20px;
    }

    .Size50Right {
        width: calc(100% - 30px);
        padding-left: 20px;
        padding-right: 20px;
    }


}

@media screen  and (min-width: 768px) and (max-width: 1024px) {

    body {
        /*Banner*/
        padding: 0px 0 0;
    }


    .SectionWidthContainer {
        width: 100%;
        vertical-align: top;
    }

    .PaddingDependsOnWidth {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }

    .AntiPaddingDependsOnWidth {
    }


}

@media screen  and (min-width: 1025px)  {

    body {
        /*Banner*/
        padding: 0px 0 0;
    }

    .SectionWidthContainer {
        width: 100%;
        vertical-align: top;
    }

    .PaddingDependsOnWidth {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }

    .AntiPaddingDependsOnWidth {
    }


}

@media screen  and (min-width: 768px) and (max-width: 1536px) {

    .RootContainer {
        width: 100%;
    }

    .RootContainerNoSidebar {
        width: 100%;
    }

    .SidebarWidth {
        width: 250px;
        display: block;
    }

    .SectionWidthContainer {
        width: 100%;
        vertical-align: top;
    }

    .PaddingDependsOnWidth {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    .AntiPaddingDependsOnWidth {
    }




    .SectionWidthHalfSize {
        width: 354px;
    }


    .ArticleMediaHeightSize {
        height: 150px;
        background-size: 768px 150px;
    }

    .ArticleMediaHeightDoubleSize {
        background-size: 768px 300px;
        height: 300px;
    }

    .ArticleMediaHeightTripleSize {
        background-size: 768px 450px;
        height: 450px;
    }


    .ShowOnMobileOnly {
        display: none !important;
    }

    .SearchFieldSizeWidth {
        width: 100%;
        margin-left: 20px;
    }


    .ShowOnlySmall {
        display: none;
    }

    .ShowOnlyMedium {
        display: none;
    }

    .ShowOnlyLarge {
        display: inline;
    }

    .TextSizeLarge {
        font-size: 22px !important;
    }

    .TextSizeLargePadding {
        padding: 15px !important;
    }

    .Size50Left {
        vertical-align: top;
        /*background: red;*/
        display: inline-block;
        width: calc(50% - 30px);
        padding-left: 20px;
        padding-right: 10px;
    }

    .Size50Right {
        vertical-align: top;
        /*background: green;*/
        display: inline-block;
        width: calc(50% - 30px);
        padding-left: 10px;
        padding-right: 20px;
    }

}

@media screen  and (min-width: 1024px) {

    .ShowOnly1024Below {
        display: none;
    }

    .ShowOnly1024Above {
        display: block;
    }

}

@media screen  and (max-width: 1023px) {

    .ShowOnly1024Below {
        display: block;
    }

    .ShowOnly1024Above {
        display: none;
    }
}

@media screen  and (min-width: 1537px)  {

    .RootContainer {
        width: 100%;

        /*width: calc(100% - 250px);*/
        /*margin-left: 250px;*/
    }


    .RootContainerNoSidebar {
        width: 100%;
    }

    .SidebarWidth {
        width: 250px;
        display: block;
    }

    .SectionWidthContainer {
        left: 50%;
        margin-left: -753px;
        position: relative;
        width: 1506px;
        /*width: 100%;*/
        vertical-align: top;
    }

    .PaddingDependsOnWidth {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .AntiPaddingDependsOnWidth {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }



    .SectionWidthHalfSize {
        width: 738px !important;
    }

    .ArticleMediaHeightSize {
        height: 300px;
    }

    .ArticleMediaHeightDoubleSize {
        height: 600px;
    }

    .ArticleMediaHeightTripleSize {
        height: 900px;
        background-size: 100% 900px;
    }


    .ShowOnMobileOnly {
        display: none !important;
    }

    .SearchFieldSizeWidth {
        width: 500px;
        position: relative;
        margin-left: -250px;
        left: 50%;

    }

    .ShowOnlySmall {
        display: none;
    }

    .ShowOnlyMedium {
        display: none;
    }

    .ShowOnlyLarge {
        display: inline;
    }

    .TextSizeLarge {
        font-size: 26px !important;
    }

    .TextSizeLargePadding {
        padding: 20px !important;
    }

    .Size50Left {
        vertical-align: top;
        /*background: red;*/
        display: inline-block;
        width: calc(50% - 30px);
        padding-left: 20px;
        padding-right: 10px;
    }

    .Size50Right {
        vertical-align: top;
        /*background: green;*/
        display: inline-block;
        width: calc(50% - 30px);
        padding-left: 10px;
        padding-right: 20px;
    }

}