.NoResultSearchButton, .NoResultSearchButton:hover {
    width: calc(100% - 40px) !important;
    height: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding-left: 40px;
    padding-right: 40px;
}


.SuggestionElementContainer {
    height: 520px;
    overflow-y: scroll;
}

.SearchTextMessage {
    padding-top: 50px;
    text-align: center;
}

.SuggestionElement {
    margin: 0px 20px 0px 20px;
    height: 40px;
    line-height: 40px;
    overflow: hidden;
    border-bottom: solid 1px;
}

.SearchTextFieldContainer {
    padding-top: 10px;
}

.SearchTextField {
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 20px;
    margin-right: 5px;
    height: 35px;
    border: none;
    width: calc(100% - 140px);
}

.SearchTextButton {
    width: 70px !important;
}

.SearchSuggestionButton {
    vertical-align: top;
    text-align: left;
    padding-left: 20px;
    border: none;
    width: calc(100% - 50px);
    /*calc(100% - 20px);*/
    height: 40px;
}

.SearchCompleteButton {
    border: none;
    margin: 0;
    width: 50px;
}

.SearchPutText {
    vertical-align: top;
    width: 30px;
    margin: 0;
    padding: 0;
    height: 38px;
    background-size: 20px 20px;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url("images/button-search-put-gray.png");
}

/*.ThemeLight .SearchPutText {*/
/*    background-image: url("images/button-search-put-black.png");*/
/*}*/

/*.ThemeDark .SearchPutText {*/
/*    background-image: url("images/button-search-put-white.png");*/
/*}*/
