.ArticleParagraphPairs {
    float: left;
    padding-bottom: 5px;
    /*border-bottom: solid 2px;*/
    margin-bottom: 5px;
}


.ArticleParagraphPairs .Row {
    padding-top: 5px;
}

.ArticleParagraphPairs .Key {
    padding-left: 10px;
    display: inline-block;
    width: calc(100% - 10px);
    font-size: 10px;
}


.ArticleParagraphPairs .Name {
    padding-left: 10px;
    display: inline-block;
    width: calc(100% - 10px);
    font-size: 14px;
}

@media screen and (min-width: 500px) and (max-width: 767px) {
    .ArticleParagraphPairs {
        width: 100%;
    }

}


@media screen  and (min-width: 768px) and (max-width: 1401px) {
    .ArticleParagraphPairs {
        width: 50%;
    }

}


@media screen  and (min-width: 1402px) and (max-width: 1785px) {
    .ArticleParagraphPairs {
        width: 33%;
    }

}


@media screen  and (min-width: 1786px) {
    .ArticleParagraphPairs {
        width: 33%;
    }

}

