
.ArticleParagraphActionsView {
    display: grid;
    column-gap: 14px;
    width: calc(100% - 2px);
    vertical-align: top;
}

.OfferButtonSize {
    width: 100%;
}



.ArticleParagraphActionsViewNarrow {
    overflow: hidden;
}



@media screen  and (max-width: 499px) {

    .ArticleParagraphActionsView {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}

@media screen and (min-width: 500px) and (max-width: 767px) {

    .ArticleParagraphActionsView {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

}


@media screen  and (min-width: 768px) and (max-width: 1023px) {

    .ArticleParagraphActionsView {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

}


@media screen  and (min-width: 1024px) and (max-width: 1535px){

    .ArticleParagraphActionsView {
        width: 100%;
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }

}



@media screen  and (min-width: 1536px) {

    .ArticleParagraphActionsView {
        width: 100%;
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }


}
