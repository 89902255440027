.SearchVisible {
    display: block;
}

.SearchHidden {
    display: none !important;
}

.DisplayLargePanel {
    margin-top: -400px;
    animation-name: MoveInLargeAnimation;
    animation-duration: 300ms;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.HideLargePanel {
    margin-top: 0;
    animation-name: MoveOutLargeAnimation;
    animation-duration: 300ms;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}


@keyframes MoveInLargeAnimation {
    0% {
        margin-top: -400px;
    }
    100% {
        margin-top: 0;
    }
}

@keyframes MoveOutLargeAnimation {
    0% {
        margin-top: 0;
    }
    100% {
        margin-top: -400px;
    }
}
