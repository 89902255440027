
.BarqboardArticleList {
    display: grid;
    column-gap: 10px;
    width: calc(100% - 2px);
    vertical-align: top;

}

.ArticleSectionItem {
    vertical-align: top;
    clear: both;
    border: solid 1px;
    padding: 10px;
    display: inline-block;
    line-height: 22px;
    overflow-y: hidden;
    margin-bottom: 15px;
    border-radius: 8px;
    height: 220px;
}

.ArticleSectionItem .ArticleSectionItemImage {
    width: 100%;
    /*padding-bottom: 30%;*/
    height: 70px;
    background-size: 100%;
    background-color: #42abc2;
    background-position: center center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow: hidden;
}

.ArticleSectionItem .ArticleSectionItemTitle {
    margin-top: 10px;
    line-height: 18px;
    font-weight: bolder;
}

.ArticleSectionItem .ArticleSectionItemExcerpt {
    width: 100%;
    margin-top: 10px;
    line-height: 18px;
    max-height: 90px;
    overflow-y: hidden;
}

@media screen  and  (max-width: 767px) {

    .BarqboardArticleListContainer {
        width: 100%;
        overflow-x: scroll;
        padding-right: 15px;
        height: auto;
        padding-bottom: 5px;
    }

    .BarqboardArticleList {
        display: inline-block;
        white-space: nowrap;
        width: auto;
        /*height: 214px;*/
    }

    .ArticleSectionItem {
        width: 200px;
    }

    .ArticleSectionItem .ArticleSectionItemTitle {
        white-space: normal;
    }

    .ArticleSectionItem .ArticleSectionItemExcerpt {
        white-space: normal;
    }

}

@media screen  and (min-width: 768px)  and (max-width: 1023px) {

    .BarqboardArticleListContainer {
        overflow-x: scroll;
        width: 100%;

    }

    .BarqboardArticleList {
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    .BarqboardArticleList .ArticleSectionItem {
        display: none;
    }

    .BarqboardArticleList .ArticleSectionItem:nth-child(-n+4) {
        display: inline-block;
    }
}


@media screen  and (min-width: 1024px)  and (max-width: 1535px) {

    .BarqboardArticleListContainer {
        overflow-x: scroll;
        width: 100%;

    }

    .BarqboardArticleList {
        display: grid;
        grid-template-columns: repeat(5, minmax(0, 1fr));
    }

    .BarqboardArticleList .ArticleSectionItem {
        display: none;
    }

    .BarqboardArticleList .ArticleSectionItem:nth-child(-n+5) {
        display: inline-block;
    }
}

@media screen  and (min-width: 1536px) {

    .BarqboardArticleListContainer {
        overflow-x: scroll;
        width: 100%;

    }

    .BarqboardArticleList {
        display: grid;
        grid-template-columns: repeat(6, minmax(0, 1fr));
    }

    .BarqboardArticleList .ArticleSectionItem {
        display: none;
    }

    .BarqboardArticleList .ArticleSectionItem:nth-child(-n+6) {
        display: inline-block;
    }
}
