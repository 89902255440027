.ThemeLight {
    background: #ffffff;
    color: #202125;
}

.ThemeLight a, .ThemeLight a:hover, .ThemeLight a:visited {
    color: #202125;
}

.ThemeLight .ThemeBackground {
    background-color: white;
    border-color: #dddddd !important;
}

.ThemeLight .ThemeHidden {
    color: white !important;
}



.ThemeLight .barqFrameButton {
    background-color: #efefef !important;
    color: #42abc2 !important;
    border: solid 1px #42abc2 !important;
}


.ThemeDark .barqFrameButton {
    background-color: #3C3F41 !important;
    color: #42abc2 !important;
    border: solid 1px #42abc2 !important;
}


.ThemeLight .redFrameButton {
    background-color: #efefef !important;
    color: #E55151 !important;
    border: solid 1px #E55151 !important;
}


.ThemeDark .redFrameButton {
    background-color: #3C3F41 !important;
    color: #E55151 !important;
    border: solid 1px #E55151 !important;
}



.ThemeLight .ThemeSolidBackground, .ThemeLight .grayButton, .ThemeLight .infoButton {
    background-color: #efefef !important;
    border-color: #cccccc !important;
}

.ThemeLight .ThemeColorOverlay {
    background-color: rgba(43, 43, 43, 0.5) !important;
}

.ThemeLight .ThemeColor {
    color: #202125 !important;
}

.ThemeLight .ThemeSolidColor, .ThemeLight .grayButton, .ThemeLight .infoButton {
    color: #606060 !important;
}

.ThemeLight .SidebarLink a {
    background: white;
    color: #606060;
}

.ThemeLight .InverseBackground {
    background-color: #dddddd !important;
    color: black !important;
}

.ThemeLight .InverseBorder {
    border-color: #666666 !important;
}

.ThemeLight .ThemeBorder {
    border-color: #dddddd !important;
}

.ThemeLight .ThemeMassiveBorder {
    border-color: #000000 !important;
}


.ThemeLight .HighlightBackground, .ThemeDark .HighlightBackground {
    background-color: #42abc2 !important;
    border-color: transparent !important;
    color: white !important;
}

.ThemeLight .TextOnHighlightBackground, .ThemeDark .TextOnHighlightBackground {
    color: white !important;
}


.HighlightBackground a, .HighlightBackground a:visited, .TextOnHighlightBackground a, .TextOnHighlightBackground a:visited {
    color: white !important;
    text-decoration: none;
}

.HighlightBackground a:hover {
    color: white !important;
    text-decoration: underline;
}



.ThemeLight .SidebarLink a:hover, .ThemeLight .SidebarLink button:hover {
    background: #efefef;
    color: #606060;
}


.ThemeLight .ThemeLogo {
    background-image: url('/barqme-webapp-logo-dark.png');
    /*background-image: url('/barqme-webapp-logo-light.png');*/

}

.ThemeLight .ThemeMenu {
    background: transparent;
    background-image: url('/menu-image-dark.png');
    /*background-image: url('/menu-image-light.png');*/
}

.ThemeLight a.ThemeSolidLink, .ThemeLight a.ThemeSolidLink:visited {
    color: #606060;
}

.ThemeLight a.ThemeSolidLink:hover {
    color: #606060;
    text-decoration: underline;
}


.ThemeLight a.ThemeNormalLink, .ThemeLight a.ThemeNormalLink:visited {
    color: #000000;
}

.ThemeLight a.ThemeNormalLink:hover {
    color: #000000;
    text-decoration: underline;
}

.ThemeLight a.ThemeStaticLink, .ThemeLight a.ThemeStaticLink:visited {
    color: #000000;
    text-decoration: none;
}

.ThemeLight a.ThemeStaticLink:hover {
    color: #000000;
    text-decoration: none;
}


.ThemeLight .ThemeButton {
    background: #ffffff;
    color: #42abc2;
    border: solid 1px #D8D8D8;
}

.ThemeLight .ShadowBottom {
    /*box-shadow: 0 8px 4px -2px #dfdfdf !important;*/
}

.ThemeDark {
    background: #2B2B2B;
    color: #ffffff;
}


.ThemeDark .ThemeBackground {
    background-color: #2B2B2B !important;
    border-color: #555555 !important;
}

.ThemeDark .ThemeHidden {
    color: #2B2B2B !important;
}

.ThemeDark input[type="text"], .ThemeDark textarea {
    background: #1a1a1a !important;;
    border-color: #666666 !important;;
    color: white !important;
}

.ThemeDark input {
    color: white !important;

}

.ThemeDark .ThemeSolidBackground, .ThemeDark .grayButton, .ThemeDark .infoButton {
    background-color: #3C3F41 !important;
    border-color: #666666 !important;;

}

.ThemeDark .ThemeColor {
    color: #ffffff !important;
}


.ThemeDark a, .ThemeDark a:hover, .ThemeDark a:visited {
    color: #ffffff;
}

.ThemeDark .ThemeSolidColor, .ThemeDark .grayButton, .ThemeDark .infoButton  {
    color: #B2B2B2 !important;
}

.ThemeDark .SidebarLink a {
    background: #2B2B2B;
    color: #B2B2B2;
}


.ThemeDark .SidebarLink a:hover, .ThemeDark .SidebarLink button:hover {
    background: #3C3F41;
    color: #B2B2B2;
}

.ThemeDark .ThemeLogo {
    background-image: url('/barqme-webapp-logo-dark.png');

}

.ThemeDark .ThemeMenu {
    background-image: url('/menu-image-dark.png');
}

.ThemeDark a.ThemeSolidLink, .ThemeDark a.ThemeSolidLink:visited {
    color: #B2B2B2;
}

.ThemeDark a.ThemeSolidLink:hover {
    color: #B2B2B2;
    text-decoration: underline;
}

.ThemeDark a.ThemeNormalLink, .ThemeDark a.ThemeNormalLink:visited {
    color: #ffffff;
}

.ThemeDark a.ThemeNormalLink:hover {
    color: #ffffff;
    text-decoration: underline;
}

.ThemeDark a.ThemeStaticLink, .ThemeDark a.ThemeStaticLink:visited {
    color: #ffffff;
    text-decoration: none;
}

.ThemeDark a.ThemeStaticLink:hover {
    color: #ffffff;
    text-decoration: none;
}



.ThemeDark .ThemeColorOverlay {
    background-color: rgba(246, 246, 246, 0.5) !important;
}


.ThemeDark .InverseBackground {
    background-color: #eeeeee !important;
    color: black !important;
}

.ThemeDark .InverseBorder {
    border-color: #424242;
}

.ThemeDark .ThemeBorder {
    border-color: #666666 !important;;
}

.ThemeDark .ThemeMassiveBorder {
    border-color: #EFEFEF !important;
}


.ThemeDark .ThemeButton {
    background: transparent;
    color: #D9DADC;
    border: solid 1px #4B4B4B;
}


.ThemeDark .ShadowBottom {
    box-shadow: 0 4px 2px -2px #999999 !important;
}


.ColorWarning {
    color: #ff6600 !important;
}


.HighlightColor {
    color: #42abc2 !important;
}

.BackgroundHighlight1 {
    background-color: #42abc2 !important;
}

.BackgroundHighlight2 {
    background-color: #E85345 !important;
}

.BackgroundHighlight3 {
    background-color: #DEB389 !important;
}

.BackgroundHighlight4 {
    background-color: #3D7AC9 !important;
}

.BackgroundHighlight5 {
    background-color: #BD939D !important;
}

.BackgroundHighlight6 {
    background-color: #D07439 !important;
}

.BackgroundHighlight7 {
    background-color: #E7B0A2 !important;
}

.BackgroundHighlight8 {
    background-color: #E1716C !important;
}

.BackgroundHighlight9 {
    background-color: #80BD89 !important;
}

.BackgroundHighlight10 {
    background-color: #6BB9EE !important;
}

.BackgroundHighlight11 {
    background-color: #79BDBE !important;
}

.BackgroundHighlight12 {
    background-color: #6DAFBD;
}

.BackgroundHighlight13 {
    background-color: #316E87;
}

.BackgroundHighlight14 {
    background-color: #4EA88B;
}

a.ColorLink, a.ColorLink:hover, a.ColorLink:visited {
    color: #42abc2 !important;
}