
.SearchResult {
    display: inline-block;
    height: 39px;
    /*margin-bottom: 4px;*/
    width: 100%;
    vertical-align: top;
}

.SearchResult img {
    vertical-align: top;
    margin-top: 2px;
    margin-right: 10px;
    width: 35px;
    height: 35px;
}

.SearchResult .counter {
    margin-top: 2px;
    display: inline-block;
    width: 60px;
    font-family: 'Roboto', sans-serif;
    /*font-size: 18px;*/
    font-weight: 300;
    height: 35px;
    text-align: right;
    line-height: 35px;
}


.SearchResult a {
    vertical-align: top;
    display: inline-block;
    margin-top: 2px;
    padding-left: 10px;
    height: 35px;
    line-height: 35px;
    width: calc(100% - 70px) !important;
}

.SearchResultSize {
    width: calc(100% - 70px) !important;
}

.SearchResultTitle {
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-weight: 300;
    margin-top: 20px;
}

.CloseOverlay {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: block;
    position: fixed;
    z-index: 99996;
}

.CloseOverlayOver {
    z-index: 99997;
    /*position: absolute;*/
}

.SubmitOffer {
    text-align: left;
    background: none;
    border: none;
    height: 30px;
    line-height: 30px;
    text-decoration: none;
    display: inline-block;
    width: calc(100% - 110px);
}

.SelectOffer {
    background: none;
    border: none;
    height: 30px;
    line-height: 30px;
    text-decoration: none;
    display: inline-block;
    width: 50px;
}


.SearchBarqs {
    width: calc(100%);
}

.ViewBarqItemCardCollection {
    display: grid;
    /*grid-template-columns: 1fr 1fr 1fr 1fr;*/
    column-gap: 10px;
    width: calc(100% - 2px);
    padding: 0;
    margin: 0;
}


.ViewBarqCollectionInfo {
    font-size: 12px;
    width: 100%;
    margin-bottom: 10px;
    padding: 0;
    text-align: left;
}

.ViewRecommendationGroups {

}


.ViewRecommendationItems {

}

.ViewRecommendationItem {
    height: 40px;
    display: inline-block;
    margin-right: 20px;
}

.ViewRecommendationItem button {
    width: 100%;
    height: 30px;
    text-align: left;
    border: none;
    background: none;
    color: #42abc2;
    font-size: 12px;
}

.SearchOffers {
    width: 100%;
    display: inline-block;
    padding-top: 10px;
    overflow-y: scroll;
    position: relative;
    z-index: 99998;
}

.SearchOfferText {
    text-align: center;
}

/*.SearchOffers button {*/
/*    text-align: left;*/
/*    background: none;*/
/*    border: none;*/
/*    display: block;*/
/*    width: 100%;*/
/*    height: 30px;*/
/*    line-height: 30px;*/
/*    color: #606060;*/
/*    text-decoration: none;*/
/*}*/

.TopSearchPanel .search .field {
    margin-top: 10px;
}


.TopSearchPanel .search .field {
    background-color: white !important;
    color: #606060 !important;
    border: solid 1px #42abc2 !important;
}

.TopSearchPanel .search .button {
    background-color: #efefef !important;
    color: #606060 !important;

}


.TopSearchPanel {
    display: inline-block;
    vertical-align: top;
    background: transparent;
    height: 58px;
    padding: 0;
    margin-top: 0;
    width: 100%;
}

.TopSearchPanel .search {
    height: 58px;
    vertical-align: top;
    padding: 0 !important;
    margin-top: 0;
    border: 0;
    /*margin-left: -50%;*/
}

.TopSearchPanel .search .field {
    width: calc(100% - 150px);
    height: 48px;
    padding: 0 20px 0 20px;
    border: solid 1px;
    border-radius: 0;
    margin: 4px 1px 0

}


.TopSearchPanel .search .button {
    width: 100px;
    height: 50px;
    border: none;
}

.ThemeDark .SearchPanelContent .CityIcon {
    background: url('/icon-city-dark.png');
    background-position: center center;
    background-size: 9px 10px;
    background-repeat: no-repeat;
}

.ThemeLight .SearchPanelContent .CityIcon {
    background: url('/icon-city-light.png');
    background-size: 9px 10px;
    background-position: center center;
    background-repeat: no-repeat;
}

.ThemeDark .SearchPanelContent .SpiceIcon {
    background: url('/icon-spice-dark.png');
    background-position: center center;
    background-size: 9px 10px;
    background-repeat: no-repeat;
}

.ThemeLight .SearchPanelContent .SpiceIcon {
    background: url('/icon-spice-light.png');
    background-size: 9px 10px;
    background-position: center center;
    background-repeat: no-repeat;
}

.ThemeDark .SearchPanelContent .CalendarIcon {
    background: url('/icon-calendar-dark.png');
    background-position: center center;
    background-size: 9px 10px;
    background-repeat: no-repeat;
}

.ThemeLight .SearchPanelContent .CalendarIcon {
    background: url('/icon-calendar-light.png');
    background-size: 9px 10px;
    background-position: center center;
    background-repeat: no-repeat;
}

.ThemeDark .SearchPanelContent .TextIcon {
    background: url('/icon-text-dark.png');
    background-position: center center;
    background-size: 9px 10px;
    background-repeat: no-repeat;
}

.ThemeLight .SearchPanelContent .TextIcon {
    background: url('/icon-text-light.png');
    background-size: 9px 10px;
    background-position: center center;
    background-repeat: no-repeat;
}


@media screen  and (max-width: 499px) {

    .ViewRecommendationItem {
        width: 100%;
    }

    .ViewBarqItemCardCollection {
        grid-template-columns: repeat(2, minmax(0, 1fr));

    }

    .ViewBarqCollectionOriginal {
        display: inline-block;
        width: 100%;
    }

}

@media screen and (min-width: 500px) and (max-width: 767px) {

    .ViewRecommendationItem {
        width: calc(50% - 20px);
    }


    .ViewBarqItemCardCollection {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    .ViewBarqCollectionOriginal {
        display: inline-block;
        width: 100%;
    }


}


@media screen  and (min-width: 768px) and (max-width: 1023px) {

    .ViewRecommendationItem {
        width: calc(50% - 20px);
    }

    .ViewBarqItemCardCollection {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    .ViewBarqCollectionOriginal {
        display: inline-block;
        width: 100%;
    }

}


@media screen  and (min-width: 1024px) and (max-width: 1535px){

    .ViewRecommendationItem {
        width: calc(33% - 20px);
    }

    .ViewBarqItemCardCollection {
        grid-template-columns: repeat(5, minmax(0, 1fr));
    }

    .ViewBarqCollectionOriginal {
        display: inline-block;
        width: 100%;
    }


}



@media screen  and (min-width: 1536px) {

    .ViewRecommendationItem {
        width: calc(33% - 20px);
    }

    .ViewBarqItemCardCollection {
        /*grid-template-columns: 1fr 1fr 1fr 1fr 1fr;*/
        grid-template-columns: repeat(6, minmax(0, 1fr));

    }

    .ViewBarqCollectionOriginal {
        display: inline-block;
        width: 100%;
    }


}
