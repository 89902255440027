
@media screen  and  (max-width: 767px) {

  .MediumTextSize {
      font-size: 12px;
  }

}

@media screen  and (min-width: 768px) and (max-width: 1023px) {

    .MediumTextSize {
        font-size: 12px;
    }

}

@media screen  and (min-width: 1024px) and (max-width: 1439px) {


    .MediumTextSize {
        font-size: 14px;
    }


}

@media screen  and (min-width: 1440px) {


    .MediumTextSize {
        font-size: 16px;
    }

}
