.ArticlePageShareImages {

}

.ArticlePageShareImages a {
    display: inline-block;
}


.ArticlePageShareImages .ArticlePageShareImage {
    width: 239px;
    height: 60px;
}


.ArticlePageShareImages .ArticlePageShareImageIos {
    background: url("images/available_app_store.png");
}

.ArticlePageShareImages .ArticlePageShareImageAndroid {
    background: url("images/available_google_play.png");
}

.ArticlePageShareImages .ArticlePageShareImageWebpage {
    background: url("images/available_webpage.png");
}