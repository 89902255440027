
.ArticlePreviewItemContainer {

}


.ArticlePreviewItem {
    border: solid 1px;
    border-radius: 10px;
    padding: 15px;
}


@media screen  and (max-width: 499px) {


    .ArticlePreviewItemContainer {
        display: block;
    }


}

@media screen  and (min-width: 500px) and (max-width: 767px) {
    .ArticlePreviewItemContainer {
        display: grid !important;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        column-gap: 20px;
    }
}


@media screen  and (min-width: 768px) and (max-width: 1401px) {

    .ArticlePreviewItemContainer {
        display: grid !important;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        column-gap: 20px;
    }


    .ArticleListItemExcerpt {
        height: 100px;
    }

}

@media screen  and (min-width: 1402px) {

    .ArticlePreviewItemContainer {
        display: grid !important;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        column-gap: 20px;
    }

    .ArticleListItemExcerpt {
        height: 100px;
    }

}
