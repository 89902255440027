.CalendarWidget {
    /*border: solid 1px #42abc2 !important;*/
    height: 100%;
}


.CalendarWidget .CalendarDayTitle {
    font-size: 10px;
}

.CalendarPrev {
    margin-left: 15px;
    width: 20px;
    height: 30px;
    display: inline-block;
    padding: 0;
    border: 0;
    background: url("images/button_calendar_prev.png") no-repeat !important;
}

.CalendarNext {
    margin-right: 15px;
    width: 20px;
    height: 30px;
    display: inline-block;
    padding: 0;
    border: 0;
    background: url("images/button_calendar_next.png") no-repeat !important;
}

.CalendarWidget .Title button, .HeaderCalendarWidget .Title button {
    width: 24px;
    height: 24px;
    border: none;
    border-radius: 0 !important;
    background: none;
    font-weight: bold;
    font-size: 15px;
}

.CalendarWidget .Title .Month {
    display: inline-block;
    font-weight: bold;
    width: calc(100% - 90px);
}

/*.HeaderCalendarWidget {*/
/*    border: solid 1px #42abc2;*/
/*}*/


.HeaderCalendarWidget {
    display: inline-block;
    position: relative;
    border: solid 1px #42abc2 !important;
    width: 315px;
    height: 265px;
    margin-left: -50px;
    z-index: 99998 !important;
}


.HeaderCalendarWidget .Title {
    padding-top: 10px;
    text-align: center;
}

.HeaderCalendarWidget .CalendarDay {
    float: left;
    width: 14%;
    text-align: center;
    height: 33px;
    line-height: 33px;
}

.CalendarHeaderSection .CalendarHeaderSectionSmaller .BarqMeInfoWidget {
    width: calc(100% - 5px);
    height: 60px;
}


.CalendarHeaderSection .TitleOnOffer {
    margin-top: 70px;
    line-height: 30px;
    font-size: 28px;
    padding: 20px;
}

.CalendarWidget .CalendarDayButton {
    width: 100%;
    height: 100%;
    font-size: 12px;
    font-weight: bolder;
}

.CalendarWidget .CalendarDayNextMonthButton {
    width: 100%;
    height: 100%;
    font-size: 12px;
    font-weight: normal;
}

.CalendarWidget .CalendarPastDay {
    font-size: 12px;
}


.CalendarHeaderSection {
    width: 100%;
}

.CalendarHeaderSection .CalendarHeaderSectionLarger {
}

.CalendarHeaderSection .CalendarHeaderSectionLarger .CalendarHeaderImage {
    width: 100%;
    height: 247px;
    margin-top: 10px;
    margin-bottom: 10px;
    background-position: center center;
    background-size: 100%;
}

.CalendarHeaderSection .CalendarHeaderSectionSmaller {
    float: right;
    margin-top: 10px;
    margin-right: 10px;
    padding: 5px 0 5px 5px;
}

.CalendarWidget {
    width: 100%;
}


.CalendarWidget .Title {
    text-align: center;
}

.CalendarWidget .CalendarDay {
    float: left;
    width: 14%;
    text-align: center;
    height: 50px;
    line-height: 50px;
}
