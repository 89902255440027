
.ErrorDialogFullOverlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: 99999;
}

.ErrorDialogContainer {
    border-color: #42abc2;
    border-top: solid 2px;
    border-bottom: solid 2px;
    position: absolute;
    left: 0;
    width: 100%;
    top: 50%;
    margin-top: -120px;
}

.ErrorDialog {
    padding:  40px 0 40px 0;
}

.CloseButtonContainer {
    text-align: right;
}




@media screen  and (min-width: 499px) and (max-width: 1785px) {

    .ErrorDialogContainer {
        width: calc(100% - 80px);
        padding: 0 40px 0 40px;
    }

}


@media screen  and (min-width: 1786px) {

    .ErrorDialogContainer {
        padding: 0;
    }

}
