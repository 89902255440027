
.LoginInfoDialog {

}

.LoginDialog {
}

.BarqmeBadge {
    position: relative;
    width: 80px;
    height: 80px;
    left: 50%;
    margin-left: -40px;
    margin-top: 20px;
}

.BarqmeInfo {
    margin-top: 0;
    text-align: justify;
}


.LoginDialog .field {
    width: calc(100% - 40px);
    height: 30px;
    padding: 10px 20px 10px 20px;
    /*border: solid 1px #42abc2;*/
    border: none;
    margin-bottom: 20px;
}

.LoginDialog .button {
    width: 100%;
    height: 50px;
    background: #42abc2;
    border: none;
    border-radius: 10px;
    margin-bottom: 10px;
    color: white;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;
}

@media screen  and (max-width: 439px) {

    .LoginDialogSize {
        width: 100%;
    }

}


@media screen  and (max-width: 767px) {

    .LoginInfoDialogSize {
        vertical-align: top;
        width: 100%;
        margin-top: 20px;
    }

    .LoginDialogSize {
        vertical-align: top;
        width: 100%;
        margin-top: 20px;
    }

}


@media screen  and (min-width: 768px) and (max-width: 879px) {

    .LoginInfoDialogSize {
        vertical-align: top;
        width: 364px;
        height: 400px;
        left:50%;
        margin-left: -364px;

        position: fixed;
        top: 50%;
        margin-top: -200px;


    }

    .LoginDialogSize {
        vertical-align: top;
        left:50%;
        margin-left: 20px;
        width: 344px;
        height: 400px;
        position: fixed;
        top: 50%;
        margin-top: -200px;
    }

}


@media screen  and (min-width: 880px) {

    .LoginInfoDialogSize {
        vertical-align: top;
        width: 400px;
        height: 400px;
        left:50%;
        margin-left: -400px;

        position: fixed;
        top: 50%;
        margin-top: -200px;


    }

    .LoginDialogSize {
        vertical-align: top;
        left:50%;
        margin-left: 40px;
        width: 400px;
        height: 400px;
        position: fixed;
        top: 50%;
        margin-top: -200px;
    }

}
