.SubscriptionItem {
    height: 60px;
    margin: 10px;
    width: calc(100% - 40px);
    max-width: 470px;
    float: left;
    padding: 10px;
    border: solid 1px;
}

.SubscriptionItem .SubscriptionItemImage {
    width: 60px;
    height: 60px;
    border-radius: 30px;
    float: left;
}

.SubscriptionItem .SubscriptionItemContent {
    float: left;
    margin-right: 70px;
    margin-left: 10px;
}


.SubscriptionItem .SubscriptionItemContent .SubscriptionItemName {

}

.SubscriptionItem .SubscriptionItemContent .SubscriptionItemText {

}


.SubscriptionItem .SubscriptionItemPublicity {
    float: right;
    width: 20px;
    height: 20px;
    padding: 20px;
    background: transparent;
}

.SubscriptionItem .SubscriptionItemPublicity .SubscriptionItemPublicityCheckbox {
    width: 20px;
    height: 20px;
    padding: 0;
    margin: 0;
}


.SubscriptionItem .SubscriptionItemPublished {
    background: green;
}

.SubscriptionItem .SubscriptionItemPrivate {
    background: #dddddd;
}

