
.HeadingTitle {
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 26px;
    margin: 0;
    padding: 0 0 10px 0;
    text-align: left !important;
}

.ParagraphButtons {
    height: 30px;
    padding: 4px 0 4px 0;
    vertical-align: middle;
    text-align: left;
}

.ParagraphButtons .MainButton {
    display: inline-block;
    width: 30px;
    height: 30px;
    padding: 0;
    margin: 0 2px 0;
    border-radius: 15px;
    border: none;
}

.ParagraphButtons .ChildButtonContainer {
    padding-left: 16px;
}

.ParagraphButtons .ChildButton {
    display: inline-block;
    width: auto;
    height: 30px;
    padding: 0 10px 0 10px;
    margin: 0 2px 0;
    border-radius: 5px;
}

.EditorRow {
    width: 100%;
    max-width: 748px;
    min-height: 51px;
    display: inline-block;
    margin: 10px 0 10px 0;
    padding-bottom: 6px;
    border-bottom: solid 1px;
}

.EditorRow label {
    /*background: red;*/
    display: inline-block;
    clear: both;
    max-width: 100%;
}

.EditorRow input, .DialogInput {
    height: 40px;
    padding-left: 10px;
    padding-right: 10px;
    border: solid 1px #ADDAE4;
}


.EditorRow select, .DialogSelect {
    height: 40px;
    padding-left: 10px;
    padding-right: 10px;
    border: solid 1px #ADDAE4;
}



.EditorRow textarea, .DialogTextField {
    height: 100px;
    width: calc(100% - 340px);
    border: solid 1px #ADDAE4;

}

.DialogFieldLarge {
    height: 150px !important;
}

.DialogFieldFull {
    width: calc( 100% - 20px) !important;
    margin-bottom: 10px;
}

.EditorBlock {
    margin-left: 10px;
    margin-right: 30px;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 20px;
    /*padding-bottom: 6px;*/
    border-bottom: solid 1px;
}

.EditorBlock .EditButtons {
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    background: red;
    /*margin-bottom: -20px;*/
}

.EditorBlock .ButtonEdit {
}

.ArticleParagraphEditorContainer {
    padding: 20px !important;
}

.ArticleParagraphEditor {
    border: solid 2px #ADDAE4 !important;
}

.ArticleSelectParagraphType {
}

.ArticleSelectParagraphTypeButton {
    width: 100px;
    height: 100px;
    padding-top: 60px;
    margin: 5px;
}

.EditorPopupDialogCloseParent {
    width: calc(100%);
    height: 30px;
    text-align: right;
}

.EditorPopupDialogClose {
    margin: 5px 5px 0;
    width: 30px;
    height: 30px;
    border: none;
}

.EditorPopupDialogContent {
    padding: 0 20px 20px 20px;
}

.ArticleParagraphActionField {
    margin-bottom: 5px;
}


.ParagraphButton {
    width: 26px;
    height: 26px;
    background-size: 20px 20px !important;
    background-repeat: no-repeat !important;
    background-position: center center !important;
    border: none;
    background: transparent;
    margin-right: 10px;
}

.ParagraphButton:hover {
    background: #ADDAE4;
}

.ParagraphButtonMove {
    background-image: url("/editor/move.png") !important;
}




.ThemeLight .ParagraphButtonAdd {
    background-image: url("/editor/add_light.png") !important;
}

.ThemeDark .ParagraphButtonAdd {
    background-image: url("/editor/add_dark.png") !important;
}

.ThemeLight .ParagraphButtonTrash {
    background-image: url("/editor/trash_light.png") !important;
}

.ThemeDark .ParagraphButtonTrash {
    background-image: url("/editor/trash_dark.png") !important;
}

.ThemeLight .ParagraphButtonOk {
    background-image: url("/editor/ok_light.png") !important;
}

.ThemeDark .ParagraphButtonOk {
    background-image: url("/editor/ok_dark.png") !important;
}

.ThemeLight .ParagraphButtonCancel {
    background-image: url("/editor/cancel_light.png") !important;
}

.ThemeDark .ParagraphButtonCancel {
    background-image: url("/editor/cancel_dark.png") !important;
}

.ArticleParagraphActionsEditorElement {
    vertical-align: middle;
    margin-bottom: 10px;
}

.ArticleParagraphActionsEditorElement button{
    width: 32px !important;
    height: 32px !important;
}

.ArticleParagraphActionsEditorField {
    width: 200px;
    height: 28px;
    border: solid 1px #82C7D7;
}

.BlockEditorTitle {
    width: calc(100% - 18px);
    padding: 10px;
    margin-bottom: 0;
}

.BlockEditorTitle button {
    background: transparent;
    border: none;
    height: 26px;
}

.ArticleParagraphTextDefaultEditorTextArea {
    margin-top: 0;
    width: calc(100% - 20px);
    padding: 10px;
    font-size: 14px;
    border: solid 1px #82C7D7;
}

.ArticleParagraphTitleEditorField {
    margin-top: 10px;
    width: calc(100% - 20px);
    padding: 10px;
    font-size: 24px;
    height: 40px;
    border: solid 1px #82C7D7;
}

.ArticleParagraphEditorField {
    margin-top: 10px;
    width: calc(100% - 20px);
    padding: 10px;
    border: solid 1px #82C7D7;
}

.ArticleParagraphYoutubeEditor {
    padding-top: 40px;
    margin-bottom: 0;

}

@media screen  and (max-width: 499px) {
    .EditorRow label {
        width: 100%;
    }


    .EditorRow .MediaSelectorComponent input {
        width: calc(100% - 60px);
        margin-top: 6px;
    }


    .EditorRow input  {
        width: calc(100% - 20px);
        margin-top: 6px;
    }


     .EditorRow select {
        width: calc(100% - 0px);
        margin-top: 6px;
    }

    .EditorRow textarea {
        width: calc(100% - 20px);
        font-size: 14px;
    }

    .DisplayRow {
        width: 100%;
    }

    .DisplayRow .DisplayRowKey {
        width: 100%;
        padding-top: 20px;
        font-weight: bold;
    }

    .DisplayRow .DisplayRowValue {
        width: 100%;
    }

    .ArticleParagraphEditorContainer {
        position: absolute;
        left: 10px;
        top: 10px;
        width: 100% !important;
        height: 100% !important;
    }

    .ArticleParagraphEditor {
        position: relative;
        width: calc(100% - 20px) !important;
        height: calc(100% - 20px) !important;

    }

    .ArticleParagraphTextDefaultEditorTextArea {
        height: 200px;
    }

    .MediaSelectorComponent input[type=text] {
        width: calc(100% - 23px);
    }

    .ArticleParagraphActionsEditorElement {
        width: 100%;
    }

}


@media screen  and (min-width: 500px) {
    .EditorRow label {
        width: 300px;
    }


    .EditorRow .MediaSelectorComponent input {
        width: calc(100% - 400px);
        margin-top: 6px;
        padding-right: 52px;
    }


    .MediaSelectorComponent input[type=text] {
        width: calc(100% - 403px);
    }


    .EditorRow input  {
        width: calc(100% - 360px);
    }

    .EditorRow select {
        width: calc(100% - 339px);
    }

    .EditorRow textarea {
        width: calc(100% - 345px);
    }


    .DisplayRow {
        padding-top: 20px;
        padding-bottom: 6px;
        width: 100%;
        border-bottom: solid 1px;
    }

    .DisplayRow .DisplayRowKey {
        display: inline-block;
        width: 50%;
        font-weight: bold;
    }

    .DisplayRow .DisplayRowValue {
        display: inline-block;
        width: 50%;
    }


    .ArticleParagraphEditorContainer {
        position: absolute;
        left: 50%;
        top: 50%;
    }

    .ArticleParagraphEditor {
        position: relative;
    }

    .ArticleParagraphTextDefaultEditorTextArea {
        height: 100px;
    }

    .ArticleParagraphActionsEditorElement {
        position: relative;
        left: 50%;
        margin-left: -230px;
        width: 460px;
        height: 36px;

    }


}

