.ViewBarqBadge {
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
}

.ViewBarqBadge .ViewBarqBadgeImage {
    display: inline-block;
    padding-right: 10px;
    width: 40px;
}

.ViewBarqBadge .ViewBarqBadgeImage img {
    width: 40px;
    height: 40px;
}

.ViewBarqBadge .ViewBarqBadgeText {
    margin-top: 15px;
    vertical-align: top;
    display: inline-block;
}

.ViewBarqBadgeList {
    display: grid !important;
    column-gap: 10px;
}


@media screen  and (max-width: 767px) {

    .ViewBarqBadgeList {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

}

@media screen  and (min-width: 768px) {

    .ViewBarqBadgeList {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

}
