
a.info, a.info:visited, a.info:hover  {
    width: 100%;
    height: auto;
    display: inline-block;
    text-align: center;
    margin-bottom: 20px;
    color: #42abc2;
    text-decoration: underline;
}
