.ModernDialog {
    display: flex;
    flex-direction: column;

    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 600px;
    height: auto;
    max-height: 80%;
    padding: 0 0 0 0;
}

.ModernDialogSizeNarrow {
    width: 90%;
    max-width: 600px;
}

.ModernDialogSizeWide {
    width: 90%;
    max-width: 800px;
}

.ModernDialog .DialogTitle {
    height: 50px;
    line-height: 50px;
    text-align: center;
    border-bottom: solid 1px;
}

.ModernDialogInfo {
    padding: 20px 20px 0 20px;
    text-align: center;
}

.ModernDialog .DialogContent {

width: 100%;
    height: calc(100% - 50px);
    overflow-y: scroll;
}

.ModernDialog .SpiceTitle {
    padding-top: 20px;
    padding-bottom: 5px;
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
}

.ModernDialog .CloseButton {
    width: 40px;
    border: none;
    height: 40px;
    padding: 10px 0 10px 0;
    vertical-align: middle;
    position: absolute;
    left: 10px;
    top: 5px;
    background: transparent url("./images/web-button-close.png") center center no-repeat;
    background-size: 15px 15px;

}

.ModernDialog .ActionButton {
    border: none;
    height: 40px;
    padding: 10px 0 10px 0;
    background-size: 20px 30px;
    vertical-align: middle;
    position: absolute;
    left: 100%;
    margin-left: -20px;
    transform: translateX(-100%);
    top: 5px;
    font-weight: bold;
    color: #42abc2;
    background: transparent;
}



.ModernDialogFirstLevel {
    z-index: 100000;
    margin-top: 0;
}


.ModernDialogSecondLevel {
    z-index: 100002;
    margin-top: 20px;

}