.Toolbar {

}

.Toolbar .ToolbarBlock{
    display: inline-block;
    padding-left: 15px;
}

.Toolbar button{
    margin-left: 10px;
    margin-bottom: 10px;
}

.Toolbar input {
    margin-left: 10px;
    height: 25px;
    border: solid 1px #42abc2;
}

.Toolbar select {
    margin-left: 10px;
    height: 35px;
    border: solid 1px #42abc2;
}



.BarqAdminList {

}

.BarqAdminList .Row {
    width: 100%;
    display: inline-block;
}

.BarqAdminList .Col {
    padding-left: 5px;
    padding-top: 4px;
    padding-bottom: 4px;
    white-space: nowrap;
    line-height: 40px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    float: left;
    height: 40px;
}


@media screen  and (max-width: 499px) {

    .LocationDataAdminListGroup {
        width: 100%;
    }

}

@media screen and (min-width: 500px) and (max-width: 767px) {
    .LocationDataAdminListGroup {
        width: 100%;
    }

}


@media screen  and (min-width: 768px) and (max-width: 1401px) {
    .LocationDataAdminListGroup {
        width: 50%;
    }

}


@media screen  and (min-width: 1402px) and (max-width: 1785px) {

    .BarqAdminList .Row .Col1 {
        width: 20px;

    }

    .BarqAdminList .Row .Col2 {
        width: 60px;
    }

    .BarqAdminList .Row .Col2 img {
        width: 40px;
        height: 40px;
    }

    .BarqAdminList .Row .Col3 {
        width: 320px;
    }

    .BarqAdminList .Row .Col4 {
        width: 150px;
    }

    .BarqAdminList .Row .Col5 {
        width: 200px;
    }

    .BarqAdminList .Row .Col6 {
        width: 80px;
    }

    .BarqAdminList .Row .Col7 {
        width: 150px;
    }

}


@media screen  and (min-width: 1786px) {

    .BarqAdminList .Row .Col1 {
        width: 20px;

    }

    .BarqAdminList .Row .Col2 {
        width: 60px;
    }

    .BarqAdminList .Row .Col2 img {
        width: 40px;
        height: 40px;
    }

    .BarqAdminList .Row .Col3 {
        width: 500px;
    }

    .BarqAdminList .Row .Col4 {
        width: 150px;
    }

    .BarqAdminList .Row .Col5 {
        width: 250px;
    }

    .BarqAdminList .Row .Col6 {
        width: 80px;
    }

    .BarqAdminList .Row .Col7 {
        width: 150px;
    }

}
