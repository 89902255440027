.ViewBarqItemTileCollection {
    display: grid;
    column-gap: 20px;
    width: 100%;
    padding: 0;
    margin: 0;
}





.BarqItemTile {
    position: relative;
    border: none;
    display: inline-block;
    vertical-align: top;

}

.BarqItemTileSize {
    width: 100%;
}


.BarqItemTileImage {
    margin: 1px 0 0 1px !important;
    top: 0;
    width: 100%;
    padding-bottom: 60%;
    overflow: hidden;
}

.BarqItemTileImage .fullBadge {
    background-repeat: no-repeat;
    background-size: 80%;
}

.BarqItemTileImageOverlayParent {
    margin: 0 0 0 0 !important;
    width: calc(100% -  2px);

    position: absolute;
    top: 0;
    padding-bottom: 60%;
    overflow: hidden;
}

.BarqItemTileImageOverlay {
    margin: 1px 0 0 1px !important;
    width: calc(100% - 3px);
    position: absolute;
    top: 0;
    height: 100%;
    background-position: center center;
    background-size: 100% auto;
    overflow: hidden;
    transition: all .5s;
}

.BarqItemTileImageOverlayParent:hover .BarqItemTileImageOverlay,
.BarqItemTileImageOverlayParent:focus .BarqItemTileImageOverlay {
    transform: scale(1.1);
}

.BarqItemTileImageDetails {
    padding: 15px 15px 10px 15px;

}

.BarqItemTileImageTitle {
    /*font-weight: bold;*/
    width: 100%;
    /*background: red;*/
    font-size: 20px;
    text-wrap: normal;
    word-wrap: break-word;
    white-space: normal;
    overflow-y: hidden;
    line-height: 22px;
    max-height: 44px;
    font-weight: bold;
}

.BarqItemTileImageText {
    padding-top: 10px;
    font-weight: normal;
    font-size: 18px;
}

.BarqItemTileImageTextTime {
    font-weight: bold;
    overflow-y: hidden;
    line-height: 22px;
    height: 22px;
}

.BarqItemTileImageTextLocation {
    overflow-y: hidden;
    line-height: 22px;
    height: 22px;
    font-size: 18px;
}


.BarqItemTileImageTextTimeToGo {
    margin-top: 5px;
    overflow-y: hidden;
    line-height: 22px;
    height: 22px;
    font-size: 18px;
}


.ViewBarqItemTileCollection .BarqItemTile {
    margin-bottom: 10px;
}

.BarqItemTileShowAll {
    clear: both;
}

.BarqItemTileShowAllContent {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    height: calc(100% - 40px);
    padding: 20px;
}
















@media screen  and (max-width: 499px) {

    .ViewBarqItemNewspaperCollection {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}

@media screen and (min-width: 500px) and (max-width: 767px) {

    .ViewBarqItemTileCollection {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

}


@media screen  and (min-width: 768px) and (max-width: 1023px) {

    .ViewBarqItemTileCollection {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

}


@media screen  and (min-width: 1024px) and (max-width: 1535px){

    .ViewBarqItemTileCollection {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

}



@media screen  and (min-width: 1536px) {

    .ViewBarqItemTileCollection {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }


}
