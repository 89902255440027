
@media screen  and (max-height: 799px) {

    .PageAboutContent {
        margin-top: 50px;
    }

}


@media screen  and (min-height: 800px)  {

    .PageAboutContent {
        width: 200px;
        height: 300px;
        position: fixed;
        left: 50%;
        top: 50%;
        margin-left: -100px;
        margin-top: -150px;
    }

}


.PageAboutTitle {
    padding-top: 10px !important;
    padding-bottom: 15px;
    font-family: 'Comfortaa', sans-serif;
    font-weight: bold;
    font-size: 24px;
    text-align: center !important;
}

.PageAboutText {
    font-size: 16px;
    text-align: center !important;
}


.PageAboutLogo {
    left: 50%;
    margin-left: -60px;
    position: relative;
    display: inline-block;
    width: 120px;
    height: 120px;
    background: url("BarqMeAboutLogo.png");
    background-size: 120px 120px;
    background-repeat: no-repeat;
    background-position: center center;
}