.ArticleParagraphMediaImagesView {
}

.ArticleParagraphMediaImagesView .HeaderImageContainer {
    margin-bottom: 20px;
}

.ArticleParagraphMediaImagesView .RoundImageTitle {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    transform: translateY(-100%);
    font-family: 'Anton', sans-serif;
    font-size: 36px;
    background: rgba(0, 0, 0, 0.3);
    color: white;
}


@media screen  and (max-width: 1024px) {

    .ArticleParagraphMediaImagesView {
        display: block;
    }


    .ArticleParagraphMediaImagesView .RoundImageContainer {
        margin: 10px 0px 10px 0px;
    }

    .ArticleParagraphMediaImagesView .RoundImage {
        border-radius: 16px;
        width: 100%;

    }

    .ArticleParagraphMediaImagesView .RoundImageTitle {
        position: absolute;
        padding-left: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
        width: calc(100% - 40px);

    }

    .ArticleParagraphMediaImagesView .HeaderImage {
        width: 100%;
    }

}

@media screen  and (min-width: 1024px) {

    .ArticleParagraphMediaImagesView {
        display: grid;
        column-gap: 10px;
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }


    .ArticleParagraphMediaImagesView .RoundImageContainer {
        margin: 20px 0px 20px 00px;
    }

    .ArticleParagraphMediaImagesView .RoundImage {
        border-radius: 16px;
        width: 100%;

    }

    .ArticleParagraphMediaImagesView .RoundImageTitle {
        position: relative;
        padding-left: 20px;
        padding-top: 10px;
        padding-bottom: 20px;
        width: calc(100% - 20px);
    }


    .ArticleParagraphMediaImagesView .HeaderImage {
        width: 100%;
    }

}
