.ContactUsForm {

}


.ContactUsForm label {
    width: 100%;
}

.ContactUsForm input[type=text] {
    margin-top: 10px;
    border: none;
    padding: 10px;
    height: 30px;
    width: calc(100% - 30px);
    margin-bottom: 30px;
}


.ContactUsForm select {
    margin-top: 10px;
    border: none;
    height: 50px;
    width: calc(100% - 10px);
    margin-bottom: 30px;
}


.ContactUsForm textarea {
    border: none;
    padding: 10px;
    height: 100px;
    width: calc(100% - 30px);
    margin-bottom: 10px;
}

.ContactUsForm button, .ContactUsForm button:hover {
    width: calc(100% - 10px) !important;
}


@media screen  and  (max-width: 767px) {
    .ContactUsForm {
        display: block;
    }
}

@media screen  and  (min-width: 768px) {
    .ContactUsForm {
        display: block;
        width: 75%;
        margin-left: 12.5%;
    }
}
