
@media screen  and (max-width: 499px) {


    .CountryElement {
        padding-left: 20px;
        padding-right: 20px;
    }

    .CityElement {
        padding-top: 10px;
        width: 100%;
        display: block;
    }

    .CityElementImage {
        display: none;
        width: 60px;
        vertical-align: top;
    }

    .CityElementImage img {
display: none;
        width: 60px;
        height: 60px;
        border-radius: 30px;
    }

    .CityElementDetails {
        display: inline-block;
        width: 100%;
        vertical-align: top;
    }

    .CityElementDetailsCityName {
        font-weight: bolder;
    }

}


@media screen  and (min-width: 500px)  {

    .CountryElement {
        padding-left: 20px;
        padding-right: 20px;
    }

    .CityElement {
        padding-top: 10px;
        width: 100%;
        display: block;
    }

    .CityElementImage {
        width: 90px;
        display: inline-block;
        vertical-align: top;
        margin-right: 10px;
    }

    .CityElementImage img {
        width: 90px;
        height: 90px;
        border-radius: 45px;
    }

    .CityElementDetails {
        display: inline-block;
        width: calc(100% - 100px);
        vertical-align: top;
    }

    .CityElementDetailsCityName {
        font-weight: bolder;
    }

}
