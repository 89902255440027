.BarqsSectionItemSmall {
    padding: 4px 0 4px 0;
    min-height: 90px;
    border-bottom: solid 1px;
}

.BarqsSectionItemSmall .image {
    /*margin-left: 10px;*/
    width: 120px;
    height: 90px;
    background-size: 120px 90px;
    background-position: center center;
    display: inline-block;
}

.BarqsSectionItemSmall .image .fullBadge {
    width: 100%;
    height: 100%;
    background-size: 90px 90px;
    background-position: center center;
    background-color: rgba(0, 0, 0, 0.1);
    background-repeat: no-repeat;
}

.BarqsSectionItemSmall .BarqData {
    display: inline-block;
    vertical-align: top;
}

.BarqsSectionItemSmall .BarqData .BarqTitle {
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    font-size: 16px;
    /*max-height: 48px;*/
    overflow-y: hidden;
}

.BarqsSectionItemSmall .BarqData .BarqDetails {
    margin-top: 4px;
    /*height: 20px;*/
    font-size: 14px;
}

.BarqsSectionItemSmall .BarqData .BarqDetails .TimeToGoString {
    padding-top: 5px;
}

.BarqsSectionItemSmall .BarqboardBarqOverlay {
    top: 0;
    width: 100%;
    height: 100%;
}

.BarqsSectionItemSmall .BarqboardBarqOverlayImage {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;

}

@media screen  and (max-width: 499px) {

    .BarqsSectionItemSmall {
        margin: 0;
        width: 100%;
    }

    .BarqsSectionItemSmall .BarqData {
        margin-left: 10px;
        width: calc(100% - 140px);
    }

    .BarqsSectionItemSmall .BarqItemCalendar {
        display: none;
    }

}

@media screen  and (min-width: 500px) and (max-width: 767px) {

    .BarqsSectionItemSmall {
        margin: 0;
        width: calc(100% - 0px);
    }

    .BarqsSectionItemSmall .BarqData {
        margin-left: 20px;
        width: calc(100% - 230px);
    }

    .BarqsSectionItemSmall .BarqItemCalendar {
        display: inline-block;

    }
}


@media screen  and (min-width: 768px) {

    .BarqsSectionItemSmall {
        margin: 0;
        width: calc(100% - 0px);
    }


    .BarqsSectionItemSmall .BarqData {
        margin-left: 20px;
        width: calc(100% - 230px);
    }

    .BarqsSectionItemSmall .BarqItemCalendar {
        display: inline-block;
    }

}
