
.OfferGroupSection {
    /*position: relative;*/
    padding-bottom: 20.58%;
    height: 0px;
    background-size: 100%;
}


.BarqboardSection {
    height: 340px;
}

.ArticleSection {
    /*padding-bottom: 20px;*/
}

.ArticleSection img {
    border-radius: 8px !important;

}

.InfoSection {
    background-position: center center;
    margin-bottom: 10px;
}


.InfoSection .InfoSectionImage {
    /*background-position: center center;*/
}


.InfoSection .InfoSectionImage .content {
    /*margin-top: 40px;*/
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 40px;
    left: 0;
    width: 80%;
    background: #00000088;
    /*height: 100px;*/
    color: white;
}

.InfoSection .content .title {
    font-size: 32px;
}

.InfoSection .content .text {
    font-size: 12px;
}


.BarqboardSubscriptionListContainer {
}

.BarqboardSubscriptionList {
    width: 100%;
    height: 110px;
    white-space: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    vertical-align: top;
    border-bottom: solid 1px;
}


.BarqboardSubscription {
    display: inline-block;
    vertical-align: top;
    margin-right: 10px;
    width: 100px;
    height: 110px;
    text-align: center;
}

.BarqboardSubscription .image {
    margin-left: 10px;
    width: 80px;
    height: 80px;
    background-size: 80px 80px;
    border-radius: 50%;
}

.BarqboardSubscription .content {
    width: 100px;
    height: 30px;
    font-size: 10px;

}

.BarqboardTagList {
    width: 100%;
    vertical-align: top;
}

a.BarqboardTag, a.BarqboardTag:hover, a.BarqboardTag:visited {
    display: inline-block;
    vertical-align: top;
    padding: 8px 15px 8px 15px;
    margin-right: 15px;
    margin-bottom: 10px;
    border-radius: 15px;
}


.EditEventButton {
    position: relative;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    border: solid 1px #dddddd;
    left: 50%;
    top: 80px;
    margin-left: -30px;
    font-size: 18px;
    background: white;
    color: black;
}

.CategorySection {
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    height: 202px;
}

.CategoryContainer {
    /*height: 222px;*/
    /*margin-top: 20px;*/
}

.Category {
    display: inline-block;
    width: 160px;
    padding: 5px;
    height: 190px;
    border: solid 1px;
    margin: 0 5px 0 5px;
}

.Category .CategoryImage {
    width: 100%;
    height: 160px;
    background-size: 160px;
    background-position: center center;
}

.Category .CategoryTitle {
    padding: 5px;
    color: white !important;
    /*display: none;*/
}

.NoInterests {
    width: 100%;
    margin-top: 100px;
    text-align: center;
}

@media screen  and (max-width: 499px) {

    .Section {
    }

    .ModernSection {
        margin-top: 40px;
        margin-bottom: 20px;
    }

}

@media screen  and (min-width: 500px) and (max-width: 767px) {

    .Section {
        /*margin-top: 5px;*/
    }

    .ModernSection {
        margin-top: 40px;
        margin-bottom: 20px;
    }

}


@media screen  and (min-width: 768px) and (max-width: 1401px) {

    .Section {
        /*margin-top: 10px;*/
    }

    .ModernSection {
        margin-top: 40px;
        margin-bottom: 20px;
    }

}

@media screen  and (min-width: 1402px) and (max-width: 1785px) {

    .Section {
        /*margin-top: 10px;*/
    }

    .ModernSection {
        margin-top: 40px;
        margin-bottom: 20px;
    }

}

@media screen  and (min-width: 1786px) {

    .Section {
        /*margin-top: 10px;*/
    }

    .ModernSection {
        margin-top: 40px;
        margin-bottom: 20px;
    }

}
