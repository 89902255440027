.DateDialog {
    margin-top: 10px;
}
@media screen and (max-width: 499px) {

    .DateDialogSize {
        /*height: 90% !important;*/
    }

}


@media screen  and (min-width: 768px) {


    .DateDialogSize {
        /*height: 460px !important;*/
    }


}
