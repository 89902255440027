
.BarqItemCalendar {
    display: inline-block;
    margin-top: 5px;
    padding-top: 10px;
    vertical-align: top;
    width: 58px;
    height: 60px;
    border: solid 1px;
}

.BarqItemCalendar .month {
    width: 100%;
    text-align: center;
    font-size: 12px;
    line-height: 14px;
    font-family: 'Anton', sans-serif;
}

.BarqItemCalendar .day {
    font-size: 19px;
    line-height: 26px;
    text-align: center;
    color: red!important;
    font-family: 'Anton', sans-serif;

}

.BarqItemCalendar .counter {
    font-size: 19px;
    line-height: 26px;
    text-align: center;
    color: #42abc2 !important;
    font-family: 'Anton', sans-serif;

}


.BarqItemCalendar .time {
    width: 100%;
    text-align: center;
    font-size: 12px;
    line-height: 14px;
    font-family: 'Anton', sans-serif;

}

/*/////////*/


.BarqItemCalendarLarge {
    display: inline-block;
    margin-top: 5px;
    padding-top: 10px;
    vertical-align: top;
    width: 80px;
    height: 80px;
    border: solid 2px;
    margin-left: 10px;
}

.BarqItemCalendarLarge .month {
    width: 100%;
    text-align: center;
    font-size: 18px;
    line-height: 20px;
    font-family: 'Anton', sans-serif;

}

.BarqItemCalendarLarge .day {
    font-size: 26px;
    line-height: 34px;
    text-align: center;
    color: red!important;
    font-family: 'Anton', sans-serif;

}

.BarqItemCalendarLarge .counter {
    font-size: 26px;
    line-height: 34px;
    text-align: center;
    color: #42abc2 !important;
    font-family: 'Anton', sans-serif;

}


.BarqItemCalendarLarge .time {
    width: 100%;
    text-align: center;
    font-size: 18px;
    line-height: 20px;
    font-family: 'Anton', sans-serif;
}

