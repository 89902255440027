
.MenuSectionContainer {
    display: grid;
    column-gap: 10px;
    width: calc(100% - 2px);
    vertical-align: top;
}


@media screen  and  (max-width: 499px) {
    .MenuSectionContainer {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }


    .BarqboardMenuSectionContainer .ImageWithTitleDisplay {
        display: none;
    }

    .BarqboardMenuSectionContainer .ImageWithTitleDisplay:nth-child(-n+6) {
        display: inline-block;
    }

}

@media screen  and (min-width: 500px) and (max-width: 767px) {

    .MenuSectionContainer {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    .BarqboardMenuSectionContainer .ImageWithTitleDisplay {
        display: none;
    }

    .BarqboardMenuSectionContainer .ImageWithTitleDisplay:nth-child(-n+8) {
        display: inline-block;
    }

}

@media screen  and (min-width: 768px) and (max-width: 1023px) {


    .MenuSectionContainer {
        grid-template-columns: repeat(5, minmax(0, 1fr));
    }


    .BarqboardMenuSectionContainer .ImageWithTitleDisplay {
        display: none;
    }

    .BarqboardMenuSectionContainer .ImageWithTitleDisplay:nth-child(-n+10) {
        display: inline-block;
    }

}

@media screen  and (min-width: 1024px) and (max-width: 1439px) {


    .MenuSectionContainer {
        grid-template-columns: repeat(6, minmax(0, 1fr));
    }


    .BarqboardMenuSectionContainer .ImageWithTitleDisplay {
        display: none;
    }

    .BarqboardMenuSectionContainer .ImageWithTitleDisplay:nth-child(-n+12) {
        display: inline-block;
    }


}

@media screen  and (min-width: 1440px) {


    .MenuSectionContainer {
        grid-template-columns: repeat(7, minmax(0, 1fr));
    }

    .BarqboardMenuSectionContainer .ImageWithTitleDisplay {
        display: none;
    }

    .BarqboardMenuSectionContainer .ImageWithTitleDisplay:nth-child(-n+14) {
        display: inline-block;
    }


}
