
.PostSectionItemContainer {
    display: grid;
    column-gap: 10px;
    width: calc(100% - 2px);
    vertical-align: top;
}

.PostSectionItem {
    vertical-align: top;
    clear: both;
    border: solid 1px;
    padding: 10px;
    display: inline-block;
    line-height: 22px;
    overflow-y: hidden;
    margin-bottom: 15px;
    border-radius: 8px;
}

.PostSectionItem .PostSectionItemAuthor {
}

.PostSectionItem .PostSectionItemAuthor .PostSectionItemImage {
    width: 24px;
    height: 24px;
    background-size: 24px 24px;
    border-radius: 12px;
    display: inline-block;
}

.PostSectionItem .PostSectionItemAuthor .PostSectionItemTitle {
    margin-left: 10px;
    display: inline-block;
}

.PostSectionItem .PostSectionItemContent .PostSectionItemTitle {
    margin-top: 8px;
    font-weight: bold;
    line-height: 17px;
    max-height: 36px;
    overflow: hidden;
}

.PostSectionItem .PostSectionItemContent .PostSectionItemText {
    margin-top: 8px;
    font-weight: normal;
    line-height: 19px;
    max-height: 76px;
    overflow: hidden;
}

.PostSectionItem .ShowPostImage {
    /*background: red;*/
    width: 90px;
    display: inline-block;
    vertical-align: top;
}

.PostSectionItem .ShowPostImage .MyPostProfilePicture {
    width: 80px;
    height: 80px;
    border-radius: 40px;
    background-size: 80px 80px;
}


.PostSectionItem .ShowPostContent {
    display: inline-block;
    width: 100%;
    max-width: 500px;
    height: 100px;
    line-height: 26px;
    text-align: justify;
    /*background: green;*/
    vertical-align: top;
    white-space: normal;
}


@media screen  and  (max-width: 499px) {


    .PostSectionItemContainer {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .PostSectionItemContainer .PostSectionItem {
        display: none;
    }

    .PostSectionItemContainer .PostSectionItem:nth-child(-n+1) {
        display: inline-block;
    }

}

@media screen  and (min-width: 500px) and (max-width: 767px) {


    .PostSectionItemContainer {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .PostSectionItemContainer .PostSectionItem {
        display: none;
    }

    .PostSectionItemContainer .PostSectionItem:nth-child(-n+2) {
        display: inline-block;
    }

}

@media screen  and (min-width: 768px) and (max-width: 1023px) {


    .PostSectionItemContainer {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    .PostSectionItemContainer .PostSectionItem {
        display: none;
    }

    .PostSectionItemContainer .PostSectionItem:nth-child(-n+3) {
        display: inline-block;
    }

}

@media screen  and (min-width: 1024px) and (max-width: 1439px) {


    .PostSectionItemContainer {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    .PostSectionItemContainer .PostSectionItem {
        display: none;
    }

    .PostSectionItemContainer .PostSectionItem:nth-child(-n+4) {
        display: inline-block;
    }

}

@media screen  and (min-width: 1440px) {

    .PostSectionItemContainer {
        grid-template-columns: repeat(5, minmax(0, 1fr));
    }

    .PostSectionItemContainer .PostSectionItem {
        display: none;
    }

    .PostSectionItemContainer .PostSectionItem:nth-child(-n+5) {
        display: inline-block;
    }

}
