.DeleteTag {
    display: inline-block;
    background: red;
    border: none;
    color: white;
    width: 16px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    border-radius: 8px;
    margin: -3px 0 0 5px;
    vertical-align: middle;
}

.NotCompletedWarning {
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
}


.EditorButtonBack {
    /*width: 40px;*/
    height: 40px;
    border: none;
    /*margin-top: -4px;*/
    font-size: 16px;
    padding-left: 0;
}

.BarqListMenu {
    margin-top: 10px;
    margin-bottom: 10px;
}

.NewEventButton {
    border: none;
    padding: 6px 10px 6px 10px;
    margin-right: 20px;

}