
.ContentFilter {
    border-radius: 15px;
}

.ContentTabContainer {
    height: 45px;
    /*background: red;*/
    text-align: left;
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: bold;
}

.ContentTab {
    padding: 0 40px 0 40px;
    display: inline-block;
    height: 45px;
    margin: 0 10px 0 10px;
    line-height: 50px;
    border-bottom: solid 3px;
    text-transform: uppercase;
}

.ContentTabSelected {
    border-color: #42abc2;
}


.ContentTabNotSelected {
    border-color: transparent;
}

.ContentToolbar {
    /*border-top: solid 1px;*/
    /*border-bottom: solid 1px;*/
    height: 40px;
    margin-bottom: 20px;
}