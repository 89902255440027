.ArticleParagraphMediaImageView .HeaderImageContainer {
    margin-bottom: 20px;
}

.ArticleParagraphMediaImageView .RoundImageTitle {
    position: absolute;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;

    transform: translateY(-100%);
    font-family: 'Anton', sans-serif;
    background: rgba(0,0,0,0.2);
    margin-bottom: -100%;
    color: white;
}


@media screen  and (max-width: 1024px) {

    .ArticleParagraphMediaImageView .RoundImageContainer {
        margin: 10px 0px 10px 0px;
    }

    .ArticleParagraphMediaImageView .RoundImage {
        border-radius: 16px;
        width: 100%;

    }

    .ArticleParagraphMediaImageView .RoundImageTitle {
        font-size: 36px;
        padding-left: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
        width: calc(100% - 40px);

    }

    .ArticleParagraphMediaImageView .HeaderImage {
        width: 100%;
    }

}

@media screen  and (min-width: 1024px) and (max-width: 1545px) {

    .ArticleParagraphMediaImageView .RoundImageContainer {
        margin: 20px 0px 20px 00px;
    }

    .ArticleParagraphMediaImageView .RoundImage {
        border-radius: 16px;
        width: 100%;

    }

    .ArticleParagraphMediaImageView .RoundImageTitle {
        font-size: 46px;
        padding-left: 20px;
        padding-top: 10px;
        padding-bottom: 20px;
        width: calc(100% - 50px);
    }


    .ArticleParagraphMediaImageView .HeaderImage {
        width: 100%;
    }

}

@media screen  and (min-width: 1546px) {

    .ArticleParagraphMediaImageView .RoundImageContainer {
        margin: 20px 0px 20px 0px;
    }

    .ArticleParagraphMediaImageView .RoundImage {
        border-radius: 16px;
        width: 100%;
    }

    .ArticleParagraphMediaImageView .RoundImageTitle {
        font-size: 66px;
        padding-left: 40px;
        padding-top: 20px;
        padding-bottom: 40px;
        width: calc(100% - 40px);

    }




}

