
.BarqItemCard {
    position: relative;
    border: solid 1px;
    border-radius: 8px;
    display: inline-block;
    vertical-align: top;

}

.BarqItemCardSize {
    width: 100%;
}


.BarqItemCardImage {
    margin: 1px 0 0 1px !important;
    top: 0;
    width: calc(100% - 2px);
    border-radius: 8px 8px 0 0;
    padding-bottom: 60%;
    overflow: hidden;
}

.BarqItemCardImage .fullBadge {
    background-repeat: no-repeat;
    background-size: 80%;
}

.BarqItemCardImageOverlayParent {
    margin: 0 0 0 0 !important;
    width: calc(100% -  2px);

    position: absolute;
    top: 0;
    padding-bottom: 60%;
    border-radius: 8px 8px 0 0;
    overflow: hidden;
}

.BarqItemCardImageOverlay {
    margin: 1px 0 0 1px !important;
    width: calc(100% - 3px);
    position: absolute;
    top: 0;
    height: 100%;
    border-radius: 8px 8px 0 0;
    background-position: center center;
    background-size: 100% auto;
    overflow: hidden;
    transition: all .5s;
}

.BarqItemCardImageOverlayParent:hover .BarqItemCardImageOverlay,
.BarqItemCardImageOverlayParent:focus .BarqItemCardImageOverlay {
    transform: scale(1.1);
}

.BarqItemCardImageDetails {
    padding: 15px 15px 10px 15px;

}

.BarqItemCardImageTitle {
    /*font-weight: bold;*/
    width: 100%;
    /*background: red;*/
    /*font-size: 18px;*/
    text-wrap: normal;
    word-wrap: break-word;
    white-space: normal;
    overflow-y: hidden;
    line-height: 22px;
    height: 44px;
    font-weight: bold;
}

.BarqItemCardImageText {
    padding-top: 10px;
    font-weight: normal;
    /*font-size: 16px;*/
}

.BarqItemCardImageTextTime {
    font-weight: bold;
    overflow-y: hidden;
    line-height: 22px;
    height: 22px;
}

.BarqItemCardImageTextLocation {
    overflow-y: hidden;
    line-height: 22px;
    height: 22px;
}


.BarqItemCardImageTextTimeToGo {
    margin-top: 5px;
    overflow-y: hidden;
    line-height: 22px;
    height: 22px;
}


.ViewBarqItemCardCollection .BarqItemCard {
    margin-bottom: 10px;
}

.BarqItemCardShowAllContent {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    height: calc(100% - 40px);
    padding: 20px;
}


@media screen  and  (max-width: 767px) {

    .BarqItemCardSize {
        width: 160px;
        margin-right: 10px;
        margin-bottom: 8px;
    }

    .BarqItemCardContentSize {
        width: 100%;
    }

    .BarqItemCardImageTitle {
        font-size: 14px;
    }

    .BarqItemCardImageText {
        font-size: 12px;
    }


    .BarqItemCardImageTitle {
        line-height: 16px;
        height: 32px;
    }

    .BarqItemCardImageTextTime {
        line-height: 16px;
        height: 16px;
    }

    .BarqItemCardImageTextLocation {
        line-height: 16px;
        height: 16px;
    }







    .BarqItemCardAll {
        text-align: center;
        word-wrap: break-word !important;
        white-space: normal;
        margin-right: 0 !important;


        width: 80px;
        height: 174px;

        position: relative;
        border: none;
        display: inline-block;
        vertical-align: top;

    }

    .BarqItemCardAllContent {
        display: flex;
        justify-content: center;
        align-items: center;
        vertical-align: middle;
        height: calc(100% - 25px);
    }


}

@media screen  and (min-width: 768px) and (max-width: 1023px) {

    .BarqItemCardImageTitle {
        font-size: 14px;
    }

    .BarqItemCardImageText {
        font-size: 12px;
    }


    .BarqItemCardImageTitle {
        line-height: 16px;
        height: 32px;
    }

    .BarqItemCardImageTextTime {
        line-height: 16px;
        height: 16px;
    }

    .BarqItemCardImageTextLocation {
        line-height: 16px;
        height: 16px;
    }


    .BarqItemCardAll {
        display: none;
    }

}

@media screen  and (min-width: 1024px) and (max-width: 1439px) {

    .BarqItemCardImageTitle {
        font-size: 14px;
    }

    .BarqItemCardImageText {
        font-size: 12px;
    }

    .BarqItemCardAll {
        display: none;
    }

}

@media screen  and (min-width: 1440px) {

    .BarqItemCardImageTitle {
        font-size: 18px;
    }

    .BarqItemCardImageText {
        font-size: 16px;
    }

    .BarqItemCardAll {
        display: none;
    }

}
