.ViewArticleParagraph {
    width: 100%;
    display: inline-block;
}

.ViewArticleParagraphTitle {
    white-space: normal;
}


.ViewArticleParagraphText {
    margin: 0px 0;
}

.ViewArticleParagraphText p {
    margin-top: 0;
}

.ViewArticleParagraphTextCode {
    width: calc(100% - 20px);
    padding: 10px;
    border: solid 1px;
    font-size: 11px;
    line-height: 12px;
}

.ViewArticleParagraphInfo {
    padding-top: 20px;
    padding-bottom: 10px;
    text-align: center;
    font-weight: bold;
}

.ViewArticleParagraphIntro {
    padding-top: 20px;
    padding-bottom: 10px;
    font-weight: 400;
    font-size: 28px;
    font-family: 'Comfortaa', sans-serif;
}

.ViewArticleParagraphSmall {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 12px;
}

.ViewArticleParagraphKnowledgeCard {
    display: inline-block;
    vertical-align: top;
    border-top: solid 1px;
    padding-bottom: 10px;
    /*background: cadetblue !important;*/
    margin-left: 20px;
    padding-top: 20px;

}

.ViewArticleParagraphKnowledgeCardLeft {
    display: inline-block;
    text-align: center;
    /*background: red;*/
    padding: 0;
}

.ViewArticleParagraphKnowledgeCardLeft img {
    width: 100%;
    height: 100%;
}

.ViewArticleParagraphKnowledgeCardRight {
    display: inline-block;
    white-space: normal;
    vertical-align: top;
    text-align: justify;
    line-height: 1.5em;
    /*background: greenyellow;*/
}

.ViewArticleParagraphKnowledgeCardRightContent {
    padding-right: 20px;
}

.ViewArticleParagraphMedia {
    background-position: center center;
    margin-bottom: 20px;
}


/*.ViewArticleParagraphList {*/
/*    padding: 0;*/
/*    margin: 16px 0 0;*/
/*    width: 100%;*/
/*}*/


/*.ViewArticleParagraphList .ViewArticleParagraphListItem {*/
/*    font-weight: bold;*/
/*    font-size: 20px;*/
/*    padding: 0;*/
/*    display: inline-block;*/
/*    width: 364px;*/
/*    margin: 0 0 16px;*/
/*}*/


.ViewArticleParagraphKnowledgeLarge {
    border-left: solid 5px #42abc2;
    width: calc(100% - 600px);
    margin: 20px 400px 20px 200px;
    padding: 20px;
    /*border-bottom: solid 1px;*/
}


.ViewArticleParagraphKnowledgeLarge .Title {
    font-size: 46px;
    font-weight: 300;
}

.ViewArticleParagraphKnowledgeLarge .Text {
    font-size: 25px;
    line-height: 1.5em;
    text-align: justify;
}

.ViewArticleParagraphKnowledgeLarge .Image {
    text-align: right;
}

.ViewArticleParagraphKnowledgeLarge .Image img {
    width: 300px;
    height: 300px;

}

.ViewArticleParagraphKnowledgeService {
    display: inline-block;
    padding: 20px;
    width: calc(33% - 40px);
    vertical-align: top;
}

.ViewArticleParagraphKnowledgeService .Title {
    font-weight: bold;
    text-align: center;
}

.ViewArticleParagraphKnowledgeService .Text {
    border-bottom: solid 1px;
    text-align: justify;
    height: 160px;
}

.ViewArticleParagraphKnowledgeService .Button {

}

.ArticleParagraphYoutubeContainerParent {
    /*margin-top: 10px;*/
    /*background: red;*/
    width: 100%;
}

.ArticleParagraphYoutubeContainer {
    display: inline-block;
    /*margin-left: auto;*/
    /*margin-right: auto;*/
    max-width: 1152px;
    /*background: green;*/
    float: left;
    width: 70%;

}

.ArticleParagraphYoutubeText {
    width: calc(30% - 60px);
    height: 100%;
    font-family: 'Anton', sans-serif;
    font-size: 42px !important;
    padding-top: 40px;
    padding-left: 20px;
    padding-right: 20px;
}


@media screen  and  (max-width: 767px) {

    .ViewArticleParagraphCol3 {
        padding: 20px 0px 20px 0px;
    }

}

@media screen  and  (min-width: 768px) {

    .ViewArticleParagraphCol3 {
        display: inline-block;
        float: left;
        width: calc(33% - 100px);
        padding: 50px 50px 20px 50px;
    }
}


@media screen  and  (max-width: 1023px) {

    .ArticleParagraphYoutubeContainer {
        width: 100%;
    }

    .ArticleParagraphYoutubeText {
        display: none !important;
    }

}

@media screen  and (min-width: 1024px) {

    .ArticleParagraphYoutubeContainer {
        width: 70%;
    }

    .ArticleParagraphYoutubeText {
        display: inline-block !important;
    }
}


.youtube_embedded {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
}

.youtube_embedded iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.ViewArticleParagraphHeaderImage {
    width: 100%;
    height: 500px;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: #B2B2B2;
}


.ArticleParagraphActionsViewShowMore {
    text-align: center;
}


.ArticleParagraphTitleSubscriptionView {
    height: auto;
    padding: 0;
    margin: 0;
}

.ArticleParagraphTitleSubscriptionView .ArticleParagraphTitleSubscriptionViewTitle {
    padding-top: 20px !important;
    padding-bottom: 5px;
    margin: 0;
    /*font-family: Roboto, serif;*/
    /*font-weight: bold;*/
    /*font-size: 24px;*/
    display: inline-block;
    width: calc(100% - 130px);

    font-weight: 700;
    font-size: 28px;
    font-family: 'Comfortaa', sans-serif;

}

.ArticleParagraphTitleSubscriptionView .ArticleParagraphTitleSubscriptionViewButton {
    padding-top: 20px !important;
    float: right;
    width: 130px;
    text-align: right;
    display: inline-block;
    vertical-align: middle;
}

.ArticleParagraphTitleSubscriptionView .ArticleParagraphTitleSubscriptionViewButton button {
    text-transform: uppercase;
}

.DialogContainer {
    padding: 20px 20px 0 20px;
}

.DialogContainer .HeaderImageContainer img {
    max-width: 100%;
}

.DialogContainer .ViewArticleParagraphKnowledgeCardLeftSize {
    width: 25%;
}

.DialogContainer .ViewArticleParagraphKnowledgeCardLeftSize img {
    position: relative;
    left: 0;
    width: 100%;
}


.DialogContainer .ViewArticleParagraphKnowledgeCardRightSize {
    padding-top: 0;
    width: 75%;
}

.DialogContainer .ArticleParagraphActionsViewNarrow {
    height: 120px;
}


@media screen  and (max-width: 499px) {

    .FullContainer .ViewArticleParagraphKnowledgeCardLeftSize {
        width: calc(100% - 40px);
    }

    .FullContainer .ViewArticleParagraphKnowledgeCardLeftSize img {
        position: relative;
        width: 50%;
        /*margin-left: -20px;*/
    }

    .FullContainer .ViewArticleParagraphKnowledgeCardRightSize {
        padding-top: 0;
        width: calc(100% - 20px);
    }

    .FullContainer .ArticleParagraphActionsViewNarrow {
        /*height: 240px;*/
    }


}

@media screen and (min-width: 500px) and (max-width: 1017px) {


    .FullContainer .ViewArticleParagraphKnowledgeCardLeftSize {
        width: 25%;
    }

    .FullContainer .ViewArticleParagraphKnowledgeCardLeftSize img {
        position: relative;
        left: 0;
        width: 100%;
    }


    .FullContainer .ViewArticleParagraphKnowledgeCardRightSize {
        padding-top: 0;
        width: 75%;
    }

    .FullContainer .ArticleParagraphActionsViewNarrow {
        height: 120px;
    }

}


@media screen  and (min-width: 1018px) and (max-width: 1401px) {


    .FullContainer .ViewArticleParagraphKnowledgeCardLeftSize {
        vertical-align: top;
        width: 100%;
    }

    .FullContainer .ViewArticleParagraphKnowledgeCardLeftSize img {
        position: relative;
        width: 50%;
    }

    .FullContainer .ViewArticleParagraphKnowledgeCardRightSize {
        vertical-align: top;
        width: 100%;
    }

    .FullContainer .ArticleParagraphActionsViewNarrow {
        height: 120px;
    }

}


@media screen  and (min-width: 1402px) and (max-width: 1785px) {

    .FullContainer .ViewArticleParagraphKnowledgeCardLeftSize {
        width: 140px;
        height: 140px;
    }

    .FullContainer .ViewArticleParagraphKnowledgeCardRightSize {
        width: calc(100% - 140px);
    }

    .FullContainer .OfferButtonSize {
        width: 370px;
    }

    .FullContainer .ArticleParagraphActionsViewNarrow {
        height: 120px;
    }

}


@media screen  and (min-width: 1786px) {

    .FullContainer .ViewArticleParagraphKnowledgeCardLeftSize {
        width: 180px;
        height: 180px;
    }

    .FullContainer .ViewArticleParagraphKnowledgeCardRightSize {
        width: calc(100% - 180px);
    }


    .FullContainer ul.ViewArticleParagraphList {
        padding: 0;
        margin: 16px 0 0;
        width: 100%;
    }


    .FullContainer ul.ViewArticleParagraphList li {
        font-weight: bold;
        font-size: 20px;
        padding: 0;
        display: inline-block;
        width: 364px;
        margin: 0 0 16px;
    }

    .FullContainer ul.ViewArticleParagraphListLarge {
        padding: 0;
        margin: 16px 0 0;
        width: 100%;
    }


    .FullContainer ul.ViewArticleParagraphListLarge li {
        display: block;
        font-size: 36px;
        font-weight: 300;
        padding: 0 180px 0 180px;
        margin: 0 0 16px;
    }

    .FullContainer .ArticleParagraphActionsViewNarrow {
        height: 120px;
    }
}

