.PageBetaAccess {
    width: calc(100% - 10px);
    max-width: 500px;
}


.PageBetaAccess input {
    width: 100%;
    height: 50px;
    font-size: 24px;
    border: solid 1px red;
}

.PageBetaAccess button {
    width: 100%;
    height: 50px;
    font-size: 16px;
    border: none;
    margin-top: 10px;
    background: red;
    color: white;

}
