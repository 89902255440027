@media screen  and (max-width: 767px) {
    .Topbar {
        margin-top: -58px;
    }
}

@media screen  and (min-width: 768px) {
    .Topbar {
        margin-top: -98px;
    }
}

.Topbar {
    top: 0;
    /*position: fixed;*/
    z-index: 99999;
    vertical-align: top;
    background: #42abc2;
    border: none;
}

.TopbarFull {
    background: #42abc2;
    /*position: fixed;*/
    left: 0;
    top: 0;
    width: 100%;
    height: 100px;
    z-index: 99998;
    border-bottom: solid 1px #82C7D7;
}


.TopbarPage {
    /*border-bottom: solid 1px #82C7D7;*/
}

.Topbar .ContactHeader {
    width: calc(100% - 20px);
    padding: 10px 10px 0 10px;
    height: 30px;
    /*background: red;*/
    font-size: 10px;
    color: white;
    /*border-bottom: solid 1px #82C7D7;*/

}

.Topbar .ContactHeader .ContactLeft {
    float: left;
    line-height: 25px;
}

.Topbar .ContactHeader .ContactLeft a {
    color: white !important;
    font-weight: bold;
}


.Topbar .ContactHeader .ContactLeft a:visited, .Topbar .ContactHeader .ContactLeft a:hover {
    color: white !important;
}


.Topbar .ContactHeader .ContactRight {
    float: right;
    font-weight: bold;
    color: white !important;
    text-transform: uppercase;
    font-size: 11px;
}

.Topbar .LoginData .Name {
    float: right;
    vertical-align: top;
    height: 58px;
    line-height: 58px;
    display: inline-block;
    margin-right: 10px;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    /*background-color: greenyellow;*/
}


.Topbar .LoginData .Image {
    float: right;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-right: 10px;;
    margin-top: 9px;
}

.Topbar .DarkMode {
    width: 55px;
    height: 30px;
    margin-top: 14px;
    float: right;
    margin-right: 10px;
}

.Topbar .LogoAndSearch .LoginData {
    vertical-align: top;
    display: inline-block;
    float: right;
    /*background-color: purple;*/
}


.Topbar .LogoAndSearch .Logo {
    display: inline-block;

}


.Topbar .LogoAndSearch .Logo .SidebarMenu {
    display: inline-block;
    border: none;
    width: 58px;
    height: 58px;
    background-color: transparent;
    background-size: 40px 40px;
    background-repeat: no-repeat;
    background-position: center center;
}

.Topbar .LogoAndSearch .Logo .TopbarAppLogo {
    width: 90px;
    height: 58px;
    background-size: 90px 58px;
    display: inline-block;
    vertical-align: top;
}


.Topbar .content {
    padding-left: 25px;
    vertical-align: top;
}


.TopbarUser {
    position: absolute;
    left: 100%;
    margin-left: -250px;
    top: 0;
    height: 58px;
    width: 250px;
    /*background: yellow;*/
}

.TopbarAnonym {
    padding-left: 80px;
    width: 170px;
    text-align: right;
}

.TopbarAnonym a {
    font-size: 13px;
    font-weight: bold;
    display: block;
    line-height: 40px;
    margin-top: 8px;
    text-align: center;
    width: 150px;
    height: 40px;
    color: white;
    background: #42abc2;
    border: none;
}

.TopbarRegular {
    font-size: 13px;
    font-weight: bold;
    padding-left: 80px;
    width: 150px;
    text-align: right;
    line-height: 58px;
    text-transform: uppercase;
}

.TopbarRegular a {
    color: #606060;
}


.TopbarMenuContainer {
    top: 0;
    /*margin-left: -10px;*/
    width: 550px;
    height: 58px;
    /*background: dodgerblue;*/
}

.TopbarMenuContainerSidebar {
    margin-left: 130px;
}

.TopbarMenuContainerNoSidebar {
    margin-left: 0;
}


.TopbarMenu {
}

.TopbarMenu ul {
    padding: 0;
    margin: 0;
}

.TopbarMenu ul li {
    list-style: none;
    display: inline-block;
    padding-left: 12px;
    padding-right: 12px;

    font-size: 10px;
    text-transform: uppercase;
    line-height: 58px;
    height: 54px;
}

.TopbarMenu ul li a {
    display: block;
    font-weight: bold;
    /*background: green;*/
    height: 58px;
}

.TopbarButton, .TopbarButton:hover, .TopbarButton:visited {
    margin-top: 13px;
    margin-right: 10px;
    display: inline-block;
    padding: 10px 0px 10px 0px;
    width: auto;
    border: none;
    /*background: red;*/
    color: white;
    text-align: center;
    margin-bottom: 10px;
    text-decoration: none;
    font-size: 10px !important;
    /*font-size: 13px;*/
    font-weight: bold;
    text-transform: uppercase;
}

.TopbarMenu ul li a:hover {
    /*color: #42abc2;*/
}

a.TopbarAction, a.TopbarAction:visited, a.TopbarAction:hover {
    padding: 5px 10px 5px 10px;
    border-radius: 5px;
    border: solid 1px white;
    color: #42abc2 !important;
}

@media screen  and (max-width: 499px) {

    .Topbar .LogoAndSearch {
        width: 100%;
        height: 58px;
        vertical-align: top;
    }

    .TopbarFull {
        height: 58px;
    }

    /*.TopbarButton, .TopbarButton:hover, .TopbarButton:visited {*/
    /*font-size: 12px !important;*/
    /*}*/
    .Topbar .LogoAndSearch .Search {
        /*display: inline-block;*/
        width: 100%;
        /*background: red;*/
        vertical-align: top;
        margin-top: -4px;
        top: 0;
        height: 58px;
    }

    .Topbar .LogoAndSearch .LoginData {
        float: right;
        position: relative;
        top: 0;
        height: 58px;
        /*width: 130px;*/
        /*margin-top: -116px;*/
    }


    .Topbar .LogoAndSearch .LoginData .Name {
        display: inline-block;
    }

}

@media screen and (min-width: 500px) and (max-width: 767px) {

    .Topbar .LogoAndSearch {
        width: 100%;
        height: 58px;
        vertical-align: top;
    }

    .TopbarFull {
        height: 58px;
    }

    .Topbar .LogoAndSearch .Search {
        display: inline-block;
        width: calc(100% - 300px);
        vertical-align: top;
    }

    .Topbar .LogoAndSearch .LoginData {
        height: 58px;
        /*width: 120px;*/
    }

    .Topbar .LogoAndSearch .LoginData .Name {
        display: none;
    }

}

@media screen and (min-width: 768px) {

    .TopbarMenu {
        margin-left: 120px;
        margin-top: -58px;
        height: 58px;
        width: calc(100% - 120px);

    }

    .Topbar .LogoAndSearch {
        width: 100%;
        height: 58px;
        vertical-align: top;
    }

    .Topbar .LogoAndSearch .Search {
        display: inline-block;
        width: calc(100% - 250px);
        vertical-align: top;
    }

    .Topbar .LogoAndSearch .LoginData {
        height: 58px;
    }

    .Topbar .LogoAndSearch .LoginData .Name {
        display: inline-block;
    }

}

.ChangeLanguageContainer {
    margin-top: 18px;
    display: block;
    float: left;
    vertical-align: top;
}

.ChangeLanguage {
    width: 30px;
    height: 22px;
    border: none;
    margin-right: 10px;
}


.ChangeLanguageHu {
    background: url("./change_lang_hu.png");
}


.ChangeLanguageEn {
    background: url("./change_lang_en.png");
}