.Sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 99998;
    border-right: solid 1px;
    overflow-y: auto;
}

.Sidebar .content {
    padding: 58px 25px 25px;
}

.Sidebar .content .title {
    margin-top: 30px;
    margin-bottom: 10px;
    text-transform: uppercase;
}

.SidebarSubscriptionRow {
    height: 40px;
    /*margin-bottom: 16px;*/
    padding-right: 20px;
}

.SidebarSubscriptionRow .SidebarSubscriptionImage {
    margin-top: 7px;
    margin-right: 10px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    float: left;
}

.SidebarSubscriptionRow .SidebarSubscriptionRowName {
    overflow-x: hidden;
}

.SidebarSubscriptionRow a {
    display: block;
    margin-left: -25px;
    padding-left: 25px;
    width: 225px;
    height: 40px;
    font-size: 13px;
    line-height: 40px;
}

.SidebarSubscriptionRow a:hover {
}

.SidebarListContainer {

}

.SidebarListContainer ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.SidebarListContainer li {
    font-size: 12px;
    list-style: none;
    margin: 0 0 0 -25px;
    width: 250px;
}

.SidebarListContainer li a, .SidebarListContainer li button {
    height: 40px;
    line-height: 40px;

    padding: 0 25px 0 25px;
    display: block;
    color: #606060;
    font-size: 13px;
    white-space: nowrap;
    overflow-x: hidden;
    border: none;
    width: calc( 100% - 50px );
    text-align: left;
}

.SidebarListContainer li a:hover, .SidebarListContainer li button:hover {
}

.SidebarBackContainer {

}
