.SearchPanelSmall .SpicesButton {
    height: 35px !important;
    text-align: left;
    margin: 0;
    padding: 0 10px 0 10px;
    border: none;
    vertical-align: top;
    width: calc(100% - 30px);
}

.SearchPanelSmall {
    margin: 0 0 5px 0;
}

.SearchPanelSmall .SearchPanelContent {
    width: 100%;
}

.SearchPanelSmall .SearchPanelContent .Full,
.SearchPanelSmall .SearchPanelContent .Left,
.SearchPanelSmall .SearchPanelContent .Right {
    display: inline-block;
    height: 35px;
}

.SearchPanelSmall .Button {
    height: 35px !important;
    text-align: left;
}

.SearchPanelSmall .SearchPanelContent .Button,
.SearchPanelSmall .SearchPanelContent .Button,
.SearchPanelSmall .SearchPanelContent .Button,
.SearchPanelSmall .SearchPanelContent input,
.SearchPanelSmall .SearchPanelContent input,
.SearchPanelSmall .SearchPanelContent input {
    width: calc(100% - 40px);
    border: none;
    height: 30px;
    padding: 3px 0 2px 10px;
    vertical-align: top;
}

.SearchPanelSmall .SearchPanelContent .SelectButton,
.SearchPanelSmall .SearchPanelContent .SelectButton,
.SearchPanelSmall .SearchPanelContent .SelectButton {
    width: calc(100% - 40px);
    border: none;
    height: 35px;
    padding: 3px 10px 2px 0px;
    text-align: left;
    margin: 0 0 0 10px !important;
    vertical-align: top;
}

.SearchPanelSmall .SearchPanelContent .ClearButton,
.SearchPanelSmall .SearchPanelContent .ClearButton,
.SearchPanelSmall .SearchPanelContent .ClearButton {
    width: 20px !important;
    border: none;
    height: 35px;
    background: url("images/button_field_clear.png") 0 3px;
    background-size: 20px 30px;
    padding: 0;
    vertical-align: top;
}


.SearchPanelSmall .SearchPanelContent .SearchPanelContentButton {
    border: none;
    background: #E55151;
    color: white;
    text-transform: uppercase;
    padding: 0 20px 0 20px;
    margin: 0;
    vertical-align: top;
}

.SearchPanelSmall .SearchPanelContent .SearchPanelRoundButton {
    text-transform: uppercase;
    font-size: 12px;
    margin-left: 10px !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    width: 35px;
    height: 35px !important;
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 17px;
    border: solid 1px;
}

.ThemeLight .SearchPanelSmall .SearchPanelContent .SearchPanelExpandButton {
    background-image: url('./images/button-expand-light.png');
}

.ThemeDark .SearchPanelSmall .SearchPanelContent .SearchPanelExpandButton {
    background-image: url('./images/button-expand-dark.png');
}


.SearchPanelSmall .Text {
    vertical-align: top;
    font-size: 12px;
    margin-right: 10px;
    display: inline-block;
    height: 35px;
    line-height: 35px;
}

.SearchPanelSmall .SearchPanelContent .Icon {
    vertical-align: top;
    display: inline-block;
    width: 10px;
    height: 35px;
    background-position: center center;
}

@media screen  and (max-width: 767px) {

    .SearchPanelSmall {
        padding: 10px 10px 1px 10px;
        position: relative;
        width: calc(100% - 10px);
        left: 0;
    }


    .SearchPanelSmall .SearchPanelContent .Field {
        display: inline-block;
        margin-bottom: 5px;
        padding: 0;
        /*padding: 0 10px 0 0;*/
        margin-right: 10px !important;
        vertical-align: top;
        height: 35px;
        border: none;
    }

    .SearchPanelSmall .SearchPanelContent .City {
        width: calc(100% - 10px);
    }

    .SearchPanelSmall .SearchPanelContent .Spices {
        width: calc(100% - 10px);
    }

    .SearchPanelSmall .SearchPanelContent .Text {
        width: calc(100% - 10px);
    }

    .SearchPanelSmall .SearchPanelContent .Date {
        width: calc(50% - 10px);
    }

    .SearchPanelContentButton {
        width: calc(100% - 10px);
        padding-left: 0;
        padding-right: 0;
    }


}



@media screen  and (min-width: 768px) and (max-width: 1023px) {

    .SearchPanelSmall {
        padding: 10px 0px 1px 10px;
        position: relative;
        width: calc(100% - 10px);
        /*height: 40px;*/
        left: 0;
    }


    .SearchPanelSmall .SearchPanelContent .Field {
        display: inline-block;
        margin-bottom: 5px;
        padding: 0;
        /*padding: 0 10px 0 0;*/
        margin-right: 10px !important;
        vertical-align: top;
        height: 35px;
        border: none;
    }

    .SearchPanelSmall .SearchPanelContent .City {
        width: calc(33% - 10px);
    }

    .SearchPanelSmall .SearchPanelContent .Spices {
        width: calc(33% - 10px);
    }

    .SearchPanelSmall .SearchPanelContent .Text {
        width: calc(33% - 10px);
    }

    .SearchPanelSmall .SearchPanelContent .Date {
        width: calc(33% - 10px);
    }

    .SearchPanelSmall .SearchPanelContentButton {
        padding-left: 0;
        width: calc(33% - 10px);
        padding-right: 0;
    }

}

@media screen  and (min-width: 1024px) and (max-width: 1535px){
    .SearchPanelSmall {
        padding: 10px 10px 1px 10px;
        position: relative;
        width: calc(100% - 20px);
        height: 40px;
        left: 0;
    }

}

@media screen  and (min-width: 1536px) {

    .SearchPanelSmall {
        padding: 10px 0 0 0;
        position: relative;
        width: 100%;
        height: 35px;
        left: 0;
    }

}


@media screen  and (min-width: 1024px) {


    .SearchPanelSmall .SearchPanelContent {
        width: 100%;
        display: grid !important;
        grid-template-columns: 15% 25% 20% 15% 15% 10% ;
        column-gap: 0;
    }

    .SearchPanelSmall .SearchPanelContent .Field {
        display: inline-block;
        margin-bottom: 5px;
        padding: 0;
        /*padding: 0 10px 0 0;*/
        margin-right: 10px !important;
        vertical-align: top;
        height: 35px;
        border: none;
    }

}
