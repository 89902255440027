.ImageWithTitleSectionSize {
    display: inline-block;
    width: 100%;
    margin-bottom: 15px;
}

.ImageWithTitleSectionNoSectionTitle {
    width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
}

.ImageWithTitleSectionNoSectionInner {
    width: calc(100% + 18px);
}

.ImageWithTitleSectionWithSectionTitle {
    width: 100%;
    overflow-y: hidden;
    /*height: 270px;*/
}

.ImageWithTitleSectionItemParent {
    position: relative;
    /*display: inline-block;*/
    vertical-align: top;
    overflow: hidden;
    padding-bottom: 100%;
    border-radius: 8px;
}

.ImageWithTitleSectionItem {
    position: absolute;
    left: 0;
    top: 0;
    /*background: blueviolet !important;*/
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
    transition: all .5s;
}

.ImageWithTitleSectionItemParent:hover .ImageWithTitleSectionItem,
.ImageWithTitleSectionItemParent:focus .ImageWithTitleSectionItem {
    transform: scale(1.1);
}


.TitleOnOffer {
    position: relative;
    left: 0;
    margin-left: 0;
    display: inline-block;
    color: white;
    width: auto;
    max-width: 80%;
    text-transform: uppercase;
}

.SmallTitleOnOffer {
    position: absolute;
    left: 0;
    top: 0;
    margin-left: 0;
    display: inline-block;
    color: white;
    width: auto;
    max-width: 80%;
    text-transform: uppercase;
    padding: 10px;
    margin-top: 25px;

}

.ImageWithTitleSelectableSelection {
    position: relative;
    left: 100%;
    top: 100%;
    margin-left: -50px;
    margin-top: -50px;
    width: 35px;
    height: 35px;
}


.ImageWithTitleSelectableSelectionOff {
    background: url("interest_select_off.png") center/35px 35px no-repeat;
}

.ImageWithTitleSelectableSelectionOn {
    background: url("interest_select_on.png") center/35px 35px no-repeat;
}

@media screen  and (max-width: 499px) {

    .TitleOnOffer {
        margin-top: 0px;
        line-height: 16px;
        font-size: 14px;
        padding: 10px;
    }

}


@media screen  and (min-width: 500px) and (max-width: 767px) {

    .TitleOnOffer {
        margin-top: 25px;
        line-height: 20px;
        font-size: 18px;
        padding: 10px;
    }

}


@media screen  and (min-width: 768px) and (max-width: 1023px) {

    .TitleOnOffer {
        margin-top: 30px;
        line-height: 20px;
        font-size: 20px;
        /*padding: 15px;*/
    }

}

@media screen  and (min-width: 1024px) and (max-width: 1439px) {


    .TitleOnOffer {
        margin-top: 60px;
        line-height: 25px;
        font-size: 22px;
        /*padding: 15px;*/
    }

}

@media screen  and (min-width: 1440px) {

    .TitleOnOffer {
        margin-top: 70px;
        line-height: 30px;
        font-size: 28px;
        padding: 20px;
    }

}
