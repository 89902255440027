.LocationDataAdminListGroup {
    float: left;
}

.LocationDataAdminListGroup .Field {
    height: 40px;
    padding-left: 10px;
    padding-right: 10px;
    border: solid 1px #ADDAE4 !important;
    width: 80%;
}

.LocationDataAdminListGroup .Row {
    padding-top: 5px;
}

.LocationDataAdminListGroup .Name {
    padding-left: 10px;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    text-overflow: ellipsis;
    float: left;
    width: calc(100% - 90px);
    overflow-x: hidden;
}

.LocationDataAdminListGroup .EditButton {
    width: 80px;
}

.AlternativeName {
    clear: both;
    line-height: 40px;
    height: 48px;
}

.AlternativeName .Field {
    height: 40px;
    padding-left: 10px;
    padding-right: 10px;
    border: solid 1px #ADDAE4 !important;
    width: 80%;
}

.AlternativeName .Col {
    padding-left: 5px;
    padding-top: 4px;
    padding-bottom: 4px;
    white-space: nowrap;
    line-height: 40px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    float: left;
    height: 40px;
}

.AlternativeName .Col1 {
    width: calc(100% - 160px);
}

.AlternativeName .Col2 {
    width: 30px;
}

.AlternativeName .Col3 {
    width: 30px;
}

.AlternativeName .Col4 {
    width: 80px;
}


@media screen  and (max-width: 499px) {

    .LocationDataAdminListGroup {
        width: 100%;
    }

}

@media screen and (min-width: 500px) and (max-width: 767px) {
    .LocationDataAdminListGroup {
        width: 100%;
    }

}


@media screen  and (min-width: 768px) and (max-width: 1401px) {
    .LocationDataAdminListGroup {
        width: 50%;
    }

}


@media screen  and (min-width: 1402px) and (max-width: 1785px) {
    .LocationDataAdminListGroup {
        width: 33%;
    }

}


@media screen  and (min-width: 1786px) {
    .LocationDataAdminListGroup {
        width: 33%;
    }

}

