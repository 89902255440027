

.CancellationItem {

}

.CancellationItem .Col {
    display: inline-block;
    color: red;
    height: 40px;
    line-height: 40px;

}

.CancellationItem .Col1 {
width: 150px;
}

.CancellationItem .Col4 {
    width: 300px;

}

.CancellationItem .Col2 {
    width: 150px;

}
.CancellationItem .Col3 {
    width: 150px;

}

