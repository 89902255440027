.Container {
    overflow-x: hidden;
}

.FullHeight {
    min-height: 100%  !important;
    position: absolute;
    top:0;
    padding: 0;
}

.FullHeightContent {
    display: flex;
    min-height: calc(100vh - 100px);
    flex-direction: column;
    justify-content: space-between;
}


.PageFooter {
    border-top: solid 1px;
    font-size: 12px;
    margin: 40px 20px 0 20px;
    padding: 40px 0 20px 0;
    text-align: center;
    /*height: 100px;*/
}


.FullHeightContent .content {
    min-height: 100%;
    position: relative;

}

.FullOverlay {
    position: fixed !important;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: 99999;
}

.FullOverlaySecond {
    position: fixed !important;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 100001;
}

.LoadingScreen {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
}

.LoadingScreen video {
    position: fixed;
    left: 50%;
    margin-left: -300px;
    top: 50%;
    margin-top: -169px;
}

.Padding {
    /*padding-left: 10px !important;*/
    /*padding-right: 10px !important;*/
    /*width: calc(100% - 20px) !important;*/
}

.PaddingEditor {
    padding-left: 10px !important;
    padding-right: 10px !important;
    margin-left: 10px !important;
    margin-right: 10px !important;
    border-bottom: solid 1px;
}

.FooterLink {
    display: inline-block;
    font-size: 13px;
    margin: 0 10px 10px 10px;
}

.InfoMessage {
    margin-top: 20px;
    margin-bottom: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: solid 2px;
    border-bottom: solid 2px;
}

.InfoMessage .Title {
    line-height: 30px;
    font-weight: bold;
}

.InfoMessage .InfoMessageSwitch {
    width: 80px;
    float: left;

}

.InfoMessage .InfoMessageContent {
    width: 100%;
}

.PaddedTop {
    padding-top: 20px;
}


@media screen  and (max-width: 894px) {
    .ScrollToTop {
        display: none;
    }

}


@media screen  and (min-width: 895px) {

    .ScrollToTop {
        position: fixed;
        left: 100%;
        margin-left: -60px;
        top: 100%;
        margin-top: -60px;
        width: 50px;
        height: 50px;
        border: none;
        padding: 0;
        background: transparent !important;
    }

}
