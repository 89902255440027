.MediaSelectorComponent {
    display: inline;
}

.MediaSelectorComponent input[type=text] {
    /*padding-right: 60px;*/
}

.MediaSelectorComponent .MediaButton {
    border-radius: 0;
    border: solid 1px;
    width: 40px;
    height: 40px;
    margin-left: -42px;
}

.MediaEditor {

}

.EditorPopupDialogContainer {
    width: 100%;
    height: 508px;
    overflow-y: scroll;
}

.EditorPopupDialogFooter {
    border-top: solid 1px;
    text-align: right;
}

.MediaEditor .UploadFile {
    display: block;
    width: 100%;
    height: 80px;
    text-align: center;
}


.MediaPreview {
    /*position:;*/
    display: inline;
    width: 200px;
    height: 200px;
    border: solid 1px;
    float: left;
    margin: 5px;
}


.MediaPreview .MediaImage {
    /*position:absolute;*/
    /*display: block;*/
    width: 200px;
    height: 200px;
}



.MediaPreview .MediaImage .Position  {
    width: 200px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}


.MediaPreview .MediaImage .Position img {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    /*display: none;*/
}

.MediaPreview .MediaSizeData {
    position: sticky;
    margin-top: -200px;
    height: 20px;
    width: 100px;
    padding: 3px;
}

.MediaPreview .Open {
    display: block;
    width: 26px;
    height: 26px;
    /*position: absolute;*/
    padding: 3px;
    margin-top: -200px;
    margin-left: 174px;
    border: none;
}


.MediaPreview .Delete {
    display: block;
    width: 26px;
    height: 26px;
    /*position: absolute;*/
    padding: 3px;
    margin-top: 150px;
    margin-left: 0;
    border: none;
}

.MediaPreview .Copy {
    display: block;
    width: 26px;
    height: 26px;
    /*position: absolute;*/
    padding: 3px;
    margin-left: 174px;
    margin-top: -26px;
    border: none;
}


.MediaPreview .Select {
    display: block;
    width: 120px;
    height: 26px;
    border: none;
    margin-top: -26px;
    margin-left: 40px;
    background: rgba(177,177,177,0.4);
}