.BarqItemListShowAll {
    margin-bottom: 20px;
}

@media screen  and (max-width: 1024px) {


    .ArticleParagraphSearchEmpty {
        font-size: 16px;
        text-align: center;
        padding: 10px;
        margin-bottom: 20px;
    }

}


@media screen  and (min-width: 1025px) {
    .ArticleParagraphSearchEmpty {
        font-size: 17px;
        text-align: center;
        padding: 20px;
        margin-bottom: 20px;
    }

}