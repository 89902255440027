
.PromoterSectionItem {
    vertical-align: top;
    clear: both;
    border: solid 1px;
    padding: 10px;
    display: inline-block;
    margin-right: 15px;
    width: 500px;
    line-height: 22px;
    overflow-y: hidden;
    margin-bottom: 15px;
    height: 150px;
    border-radius: 8px;
}

.PromoterSectionItem .ShowPostImage {
    width: 100px;
    display: inline-block;
    vertical-align: top;
}

.PromoterSectionItem .ShowPostImage .MyPostProfilePicture {
    border: solid 4px white !important;
    margin-left: 10px;
    margin-top: -50px;
    width: 80px;
    height: 80px;
    border-radius: 42px;
    background-size: 80px 80px;
}


.PromoterSectionItem .ShowPostContent {
    display: inline-block;
    width: 100%;
    max-width: 400px;
    margin-top: 10px;
    height: 85px;
    max-height: 286px;
    line-height: 26px;
    text-align: justify;
    vertical-align: top;
    white-space: normal;
}


.ViewBarqPromoter {
    vertical-align: top;
    clear: both;
    border: solid 1px;
    padding: 10px;
    display: inline-block;
    margin-right: 5px;
    width: calc(100% - 42px);
    line-height: 22px;
    overflow-y: hidden;
    margin-top: 20px;
    margin-bottom: 15px;
    /*height: 150px;*/
}

.ViewBarqPromoter .ShowPostImage {
    width: 100px;
    display: inline-block;
    vertical-align: top;
}

.ViewBarqPromoter .ShowPostImage .MyPostProfilePicture {
    border: solid 4px white !important;
    margin-left: 10px;
    margin-top: -50px;
    width: 80px;
    height: 80px;
    border-radius: 42px;
    background-size: 80px 80px;
}


.ViewBarqPromoter .ShowPostContent {
    display: inline-block;
    width: calc(100% - 100px);
    margin-top: 5px;
    height: 30px;
    line-height: 26px;
    vertical-align: top;
    white-space: normal;
}

.ViewBarqPromoter .ViewBarqPromoterSubscribe {
    position: relative;
    display: block;
    left: 100%;
    top: 50%;
    transform: translateX(-110%);
    /*transform: translateY(-50%);*/
}