.PageChangePasswordForm {
    padding-top: 20px;
    width: 800px;
    max-width: 100%;
 position: relative;
    left: 50%;
    transform: translateX(-50%);
}
.PageChangePassword .field {
    width: calc(100% - 30px);
    height: 30px;
    padding: 10px 15px 10px 15px;
    border: none;
    margin-bottom: 20px;
}

.PageChangePassword .button {
    width: 100%;
    height: 50px;
    background: #42abc2;
    border: none;
    margin-bottom: 20px;
    color: white;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;
}
