.ViewOccurence {
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
}

.ViewOccurence .ViewOccurenceTime {
    margin-top: 15px;
    display: inline-block;
    padding-right: 10px;
    width: 170px;
}


.ViewOccurence .ViewOccurenceLocation {
    margin-top: 15px;
    vertical-align: top;
    display: inline-block;
}


.ViewOccurenceList {
    display: grid !important;
    column-gap: 10px;
}


@media screen  and (max-width: 767px) {

    .ViewOccurenceList {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

}

@media screen  and (min-width: 768px) {

    .ViewOccurenceList {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

}
