
.ArticlesContainer .ArticleListItem2 {
    border-bottom: solid 1px;
    margin-bottom: 20px;
    height: 373px;
}

.ArticlesContainer .ArticleListItemInfo {
    font-size: 12px;
    margin-bottom: 10px;

}

.ArticlesContainer .ArticleListItemExcerpt {
    margin-bottom: 10px;
    text-align: justify;
}


.ArticleExcerpt, .ServiceArticleExcerpt {
    padding: 10px;
    border: solid 1px;
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 20px;
    vertical-align: top;
}

.ArticleExcerptImage .Image {
    width: 100%;
    height: 120px;
    background-position: center center;
    background-size: auto 100%;
}


.ArticleExcerptTitle {
    font-weight: 300;
    font-size: 18px;
    padding-top: 10px;
    padding-bottom: 10px;
    height: 45px;
    color: #42abc2;
}

.ArticleExcerptTitle a, .ArticleExcerptTitle a:hover, .ArticleExcerpt .ArticleExcerptTitle a:visited {
    color: #42abc2;
    text-decoration: none;
}

.ArticleExcerptText {
    text-align: justify;
}

.ArticleListElement .Col {
    padding-left: 5px;
    padding-top: 4px;
    padding-bottom: 4px;
    white-space: nowrap;
    line-height: 40px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    float: left;
    height: 40px;

}


@media screen  and (max-width: 499px) {
    .ArticleExcerpt {
        width: calc(100% - 20px);
    }

    .ArticleExcerpt .ArticleExcerptText {
        text-align: justify;
    }

    .ArticleListElement {
        width: 100%;
        display: inline-block;
    }


    .ArticleListElement .Col0 {
        width: 100%;
    }

    .ArticleListElement .Col0 img {
        width: 100%;
        height: auto;
    }

    .ArticleListElement .Col1 {
        width: 100%;
    }

    .ArticleListElement .Col2 {
        width: 100%;
    }

    .ArticleListElement .Col3 {
        width: 0;
        height: 0;
    }

    .ArticleListElement .Col4 {
        width: 100%;
    }

    .ArticleListElement .Col5 {
        width: 0;
        height: 0;
    }

    .ArticleListElement .Col6 {
        width: 100%;
    }


}


@media screen  and (min-width: 500px) {
    .ArticleExcerpt {
        width: 280px;
        height: 290px;
    }

    .ArticleExcerpt .ArticleExcerptText {
        height: 100px;
    }


    .ArticleListElement {
        width: 100%;
        display: inline-block;
    }


    .ArticleListElement .Col0 {
        width: 100%;
    }

    .ArticleListElement .Col0 img {
        width: 100%;
        height: auto;
    }

    .ArticleListElement .Col1 {
        width: 150px;
    }

    .ArticleListElement .Col2 {
        width: 190px;
    }

    .ArticleListElement .Col3 {
        width: 0px;
    }

    .ArticleListElement .Col4 {
        width: 150px;
    }

    .ArticleListElement .Col5 {
        width: 0px;
    }

    .ArticleListElement .Col6 {
        width: 95px;
    }


}


@media screen  and (max-width: 619px) {
    .ServiceArticleExcerpt {
        margin-left: 10px;
        margin-right: 0px;
        width: calc(100% - 40px);
    }

    .ArticleExcerptTitle {
        height: auto !important;
    }

    .ServiceArticleExcerpt .ArticleExcerptText {
        height: auto !important;
    }

}

@media screen and (min-width: 620px) and (max-width: 1017px) {

    .ServiceArticleExcerpt {
        margin-left: 20px;
        margin-right: 0px;
        /*margin: 20px;*/
        width: calc(50% - 50px);
        height: 360px;
    }

    .ArticleExcerptTitle {
        height: auto !important;
    }

    .ServiceArticleExcerpt .ArticleExcerptText {
        height: 160px;
        overflow-y: hidden;
    }


    .ArticleListElement {
        width: 100%;
        display: inline-block;
    }


    .ArticleListElement .Col0 {
        width: 0px;
    }

    .ArticleListElement .Col0 img {
        width: 0px;
        height: 0px;
    }

    .ArticleListElement .Col1 {
        width: calc(30% - 34px);
    }

    .ArticleListElement .Col2 {
        width: calc(30% - 34px);
    }

    .ArticleListElement .Col3 {
        width: 0px;
    }

    .ArticleListElement .Col4 {
        width: calc(30% - 34px);
    }

    .ArticleListElement .Col5 {
        width: 0px;
    }

    .ArticleListElement .Col6 {
        width: 95px;
    }

}


@media screen  and (min-width: 1018px) and (max-width: 1401px) {

    .ServiceArticleExcerpt {
        margin-left: 20px;
        margin-right: 0px;
        /*margin: 20px;*/
        width: calc(50% - 50px);
        height: 410px;
    }

    .ServiceArticleExcerpt .ArticleExcerptText {
        height: 220px;
        overflow-y: hidden;
    }

    .ArticleListElement {
        width: 100%;
        display: inline-block;
    }


    .ArticleListElement .Col0 {
        width: 102px;
    }

    .ArticleListElement .Col0 img {
        margin-top: 10px;
        width: 102px;
        height: 21px;
    }

    .ArticleListElement .Col1 {
        width: calc(30% - 70px);
    }

    .ArticleListElement .Col2 {
        width: calc(30% - 50px);
    }

    .ArticleListElement .Col3 {
        width: 0px;
    }

    .ArticleListElement .Col4 {
        width: calc(30% - 30px);
    }

    .ArticleListElement .Col5 {
        width: 0px;
    }

    .ArticleListElement .Col6 {
        width: 95px;
    }


}


@media screen  and (min-width: 1402px) and (max-width: 1785px) {

    .ServiceArticleExcerpt {
        margin-left: 20px;
        margin-right: 0px;
        /*margin: 20px;*/
        width: calc(33% - 45px);
        height: 410px;
    }


    .ServiceArticleExcerpt .ArticleExcerptText {
        height: 220px;
        overflow-y: hidden;
    }


    .ArticleListElement {
        width: 100%;
        display: inline-block;
    }


    .ArticleListElement .Col0 {
        width: 192px;
    }

    .ArticleListElement .Col0 img {
        width: 192px;
        height: 40px;
    }

    .ArticleListElement .Col1 {
        width: 240px;
    }

    .ArticleListElement .Col2 {
        width: 220px;
    }

    .ArticleListElement .Col3 {
        width: 180px;
    }

    .ArticleListElement .Col4 {
        width: 150px;
    }

    .ArticleListElement .Col5 {
        width: 0px;
    }

    .ArticleListElement .Col6 {
        width: 95px;
    }



}


@media screen  and (min-width: 1786px) {

    .ServiceArticleExcerpt {
        margin-left: 20px;
        margin-right: 0px;
        /*margin: 20px;*/
        width: calc(33% - 45px);
        height: 340px;
    }


    .ServiceArticleExcerpt .ArticleExcerptText {
        height: 160px;
        overflow-y: hidden;
    }

    .ArticleListElement {
        width: 100%;
        display: inline-block;
    }



    .ArticleListElement .Col0 {
        width: 192px;
    }

    .ArticleListElement .Col0 img {
        width: 192px;
        height: 40px;
    }

    .ArticleListElement .Col1 {
        width: 200px;
    }

    .ArticleListElement .Col2 {
        width: 300px;
    }

    .ArticleListElement .Col3 {
        width: 180px;
    }

    .ArticleListElement .Col4 {
        width: 150px;
    }

    .ArticleListElement .Col5 {
        width: 340px;
    }

    .ArticleListElement .Col6 {
        width: 95px;
    }


    .ArticlesContainer {
        width: 100%;
    }


    .ArticlesContainer .ArticleSidebar {
        float: right;
        width: 350px;
    }


    .ArticlesContainer .ArticleList2 {
        position: relative;
        margin-top: 40px;
        left: calc(50% - 384px);
        width: 768px;
    }

    .ArticlesContainer .ArticleSidebar2 {
        /*position: fixed;*/
        padding: 40px;
        width: calc(100% - 80px);
        height: 688px;
        /*border-radius: 40px;*/
    }


}

