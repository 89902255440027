.MyInterestItemContainer {
    /*display: inline-flex;*/
    /*width: 100%;*/
    /*justify-content: space-evenly;*/
}

.MyInterestItem {
    width: calc(100% - 40px);
    margin-top: 20px;
    border: solid 1px;
    padding: 5px;

}

.MyInterestItemTitle {
    line-height: 35px;
    vertical-align: middle;
    display: inline-block;
    width: calc(100% - 145px);
    padding-left: 35px;
    background: url("./drag_marker.png") no-repeat left;
    background-size: 20px 20px;
}

.MyInterestItem .ButtonRename {
    background: url("button_rename.png");
}

.MyInterestItem .ButtonDelete {
    background: url("button_delete.png");
}

.MyInterestItemButtons {
    float: right;
    vertical-align: top;
    display: inline-block;
    width: 100px;
}

.MyInterestItemUnsubscribeButton {
    vertical-align: top;
    border: solid 1px;
    border-radius: 8px;
    width: 35px;
    height: 35px;
    margin-left: 10px;
    margin-right: 0;
    margin-bottom: 0 !important;
}