.SearchImage {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-image: url("./images/barqme-search-background-theatre.png");
}

.SearchPanelTitle {
    margin: 0 0 20px 0;
    padding: 0;
    padding-left: 38px;
    background: url("./images/barqme-search-logo.png") no-repeat;
    background-size:  28px 28px;
}

.SearchPanelTitleHistory {
    width: 40px;
    height: 40px;
    border: none;
    display: inline-block;
    background-position: center center;
    float: right;
    background-size: 30px 30px !important;
    background-repeat: no-repeat !important;
}

.ThemeLight .SearchPanelTitleHistory {
    background: url("images/search-history-light.png");
}

.ThemeDark .SearchPanelTitleHistory {
    background: url("images/search-history-dark.png");
}

.SearchArticleLink {
    margin-top: 10px;
    font-size: 12px;
}

.SearchPanelLarge .SearchPanelContent, .SearchPanelSidebar .SearchPanelContent{
    /*opacity: 0.90;*/
    width: 100%;
}

.SearchPanelLarge .SearchPanelContent .Full,
.SearchPanelLarge .SearchPanelContent .Left,
.SearchPanelLarge .SearchPanelContent .Center,
.SearchPanelLarge .SearchPanelContent .Right,
.SearchPanelSidebar .SearchPanelContent .Full,
.SearchPanelSidebar .SearchPanelContent .Left,
.SearchPanelSidebar .SearchPanelContent .Center,
.SearchPanelSidebar .SearchPanelContent .Right  {
    display: inline-block;
    height: 50px;
}

.SearchPanelLarge .Button, .SearchPanelSidebar .Button {
    height: 50px !important;
    text-align: left;
}

.SearchPanelLarge .SearchPanelContent .Full button,
.SearchPanelLarge .SearchPanelContent .Left button,
.SearchPanelLarge .SearchPanelContent .Center button,
.SearchPanelLarge .SearchPanelContent .Full input,
.SearchPanelLarge .SearchPanelContent .Left input,
.SearchPanelLarge .SearchPanelContent .Center input,
.SearchPanelLarge .SearchPanelContent .Right input,
.SearchPanelSidebar .SearchPanelContent .Full button,
.SearchPanelSidebar .SearchPanelContent .Left button,
.SearchPanelSidebar .SearchPanelContent .Center button,
.SearchPanelSidebar .SearchPanelContent .Full input,
.SearchPanelSidebar .SearchPanelContent .Left input,
.SearchPanelSidebar .SearchPanelContent .Center input,
.SearchPanelSidebar .SearchPanelContent .Right input {
    width: calc(100% - 50px);
    border: none;
    height: 30px;
    padding: 10px 0 10px 0;
}

.SearchPanelLarge .SearchPanelContent .Full .SelectButton,
.SearchPanelLarge .SearchPanelContent .Left .SelectButton,
.SearchPanelLarge .SearchPanelContent .Center .SelectButton,
.SearchPanelLarge .SearchPanelContent .Right .SelectButton,
.SearchPanelSidebar .SearchPanelContent .Full .SelectButton,
.SearchPanelSidebar .SearchPanelContent .Left .SelectButton,
.SearchPanelSidebar .SearchPanelContent .Center .SelectButton,
.SearchPanelSidebar .SearchPanelContent .Right .SelectButton {
    width: calc(100% - 50px);
    border: none;
    height: 50px;
    text-align: left;
}

.SearchPanelLarge .SearchPanelContent .Full .ClearButton,
.SearchPanelLarge .SearchPanelContent .Left .ClearButton,
.SearchPanelLarge .SearchPanelContent .Center .ClearButton,
.SearchPanelLarge .SearchPanelContent .Right .ClearButton,
.SearchPanelSidebar .SearchPanelContent .Full .ClearButton,
.SearchPanelSidebar .SearchPanelContent .Left .ClearButton,
.SearchPanelSidebar .SearchPanelContent .Center .ClearButton,
.SearchPanelSidebar .SearchPanelContent .Right .ClearButton {
    width: 20px;
    border: none;
    height: 30px;
    padding: 10px 0 10px 0;
    background: url("/button_field_clear.png");
    background-size: 20px 30px;
}


.SearchPanelLarge .SearchPanelContent .SearchPanelContentButton, .SearchPanelSidebar .SearchPanelContent .SearchPanelContentButton  {
    border: none;
    background: #E55151;
    text-transform: uppercase;
    color: white;
    font-size: 12px;
    height: 50px;
    margin-bottom: 5px;

}

.SearchPanelLarge .SearchPanelContent .Icon, .SearchPanelSidebar .SearchPanelContent .Icon  {
    vertical-align: top;
    display: inline-block;
    width: 20px;
    height: 50px;
    background-position: center center;
}

.SearchImage .SearchPanelRoundButton {
    position: absolute;
    left: 100%;
    top: 3px;
    margin-left: -40px;
    /*top: 100%;*/
    /*margin-top: -3px !important;*/

    text-transform: uppercase;
    font-size: 12px;
    margin-top: 3px !important;
    margin-bottom: 0 !important;
    width: 35px;
    height: 35px !important;
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 17px;
    border: solid 1px;
}

.ThemeLight .SearchImage .SearchPanelCollapseButton {
    background-image: url('./images/button-collapse-light.png');
}

.ThemeDark .SearchImage .SearchPanelCollapseButton {
    background-image:  url('./images/button-collapse-dark.png');
}



@media screen  and (max-width: 767px) {

    .SearchImage {
        width: 100%;
        height: 430px;
    }

    .SearchPanelLarge {
        vertical-align: top;
        position: relative;
        width: 100%;
        /*height: 410px;*/
        padding: 20px 10px 0 10px;
        margin: 0;
    }

    .SearchPanelLarge .SearchPanelContent {
        padding: 10px;
        width: calc(100% - 40px);
    }

    .SearchPanelLarge .SearchPanelContent .Full {
        width: 100%;
        margin-bottom: 5px;
        vertical-align: top;
    }

    .SearchPanelLarge .SearchPanelContent .Left {
        width: calc(50% - 5px);
        margin-bottom: 5px;
        margin-right: 10px;
        vertical-align: top;
    }

    .SearchPanelLarge .SearchPanelContent .Center {
        width: calc(50% - 5px);
        margin-bottom: 5px;
        vertical-align: top;
    }

    .SearchPanelLarge .SearchPanelContent .Right {
        width: calc(50% - 5px);
        margin-bottom: 5px;
        vertical-align: top;
    }


    .SearchPanelLarge .SearchPanelContent .SearchPanelContentButton {
        width: 100%;
        height: 50px;
        margin-bottom: 5px;
    }


}

@media screen  and (min-width: 768px) and (max-width: 1401px) {

    .SearchImage {
        width: 100%;
        height: 400px;
        align-items: center;
    }


    .SearchPanelLarge {
        position: absolute;
        top: 50px;
        left: 50%;
        margin-left: -360px;
        width: 720px;
    }



    .SearchPanelLarge .SearchPanelContent {
        padding: 20px;
        width: calc(100% - 40px);
    }

    .SearchPanelLarge .SearchPanelContent .Full {
        width: 100% !important;
        margin-bottom: 5px;
        vertical-align: top;
    }

    .SearchPanelLarge .SearchPanelTri {
        display: grid !important;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        column-gap: 10px;
    }

    .SearchPanelLargeEmpty {
        height: 400px;
    }

}


@media screen  and (min-width: 1402px) {

    .SearchImage {
        width: 100%;
        height: 400px;
        display: grid;
        align-items: center;
    }


    .SearchPanelLarge {
        position: absolute;
        top: 50px;
        width: 768px;
        left: 50%;
        margin-left: -384px;
    }


    .SearchPanelLarge .SearchPanelContent {
        padding: 20px;
        width: calc(100% - 40px);
    }


    .SearchPanelLarge .SearchPanelContent .Full {
        width: 100% !important;
        margin-bottom: 10px;
        vertical-align: top;
    }

    .SearchPanelLarge .SearchPanelTri {
        display: grid !important;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        column-gap: 10px;
    }

    .SearchPanelLargeEmpty {
        height: 400px;
    }


}
