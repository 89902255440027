
.ShowFestivalContainer {
    /*max-width: 1024px;*/
}

.StageContainer {
    /*background: red;*/
    width: 100%;
}

.ShowFestivalParent {
    margin-bottom: 20px;
    clear: both;
}

.ShowFestival {
    margin-top: 20px;
    width: 100%;
}

.ShowStage {
    width: 100%;
}

.ShowFestival .FestivalTitle, .ShowStage .FestivalTitle {
    display: block;
    width: auto;
    background: rgba(0, 0, 0, 0.65);
    border-radius: 6px;
    color: white;
    position: relative;
    max-width: 80%;
    /*margin-top: -20px;*/
}


.FestivalListElement {
    width: 100%;
    display: inline-block;
}

.FestivalListElement .Col {
    padding-left: 5px;
    padding-top: 4px;
    padding-bottom: 4px;
    white-space: nowrap;
    line-height: 40px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    float: left;
    height: 40px;

}


.FestivalListElement .Col0 {
    width: 192px;
}

.FestivalListElement .Col0 img {
    width: 192px;
    height: 40px;
}

.FestivalListElement .Col1 {
    width: 420px;
}

.FestivalListElement .Col2 {
    width: 420px;
}

.FestivalListElement .Col3 {
    width: 95px;
}
