.PostItem {
    border-bottom: solid 1px;
    padding: 10px;
    width: calc(100% - 20px);
}

.PostItem .PostItemAuthorImage {
    width: 50px;
    display: inline-block;
    vertical-align: top;
}

.PostItem .PostItemAuthorImage .Image {
    width: 50px;
    height: 50px;
    border-radius: 25px;
}

.PostItem .PostItemContent {
    width: calc(100% - 80px);
    vertical-align: top;
    display: inline-block;
    margin-left: 20px;
}