
.PromoterTitle {
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 32px;
    margin: 0;
    text-align: left !important;
}

.PromoterHeader {
    padding-top: 20px;
    /*padding: 20px 0;*/
}

.PromoterName {
    vertical-align: middle;
    width: calc(100% - 70px);
    display: inline-block;
}

.PromoterLabel {
    vertical-align: middle;
    width: 70px;
    display: inline-block;
}

.PromoterLabelCaptionContainer {
}

.PromoterLabelCaptionContainer .PromoterLabelCaption {
    font-size: 9px;
    font-weight: bold;
}



.TabHeader {
    margin-top: -60px;
    padding: 20px 0 20px 0;
}

.TabHeader .TabTitle {

    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 28px;
    margin: 0;
    text-align: left !important;

    vertical-align: middle;
    width: calc(100% - 70px);
    display: inline-block;

}

.TabHeader .TabSavePanel {
    width: 100%;
    display: inline-block;
    vertical-align: middle;
    /*width: 70px;*/
}


.TabHeader .TabSavePanel .Button:disabled {
    padding: 6px 10px 6px 10px;
    height: 40px;
    background: #42abc2;
    color: #dddddd;
    border: none;
    font-weight: bold;
}



.PromoterMenu {
}

.PromoterMenu .PromoterMenuButton {
    border: none;
    padding: 6px 10px 6px 10px;
    margin: 5px;
    font-size: 13px;
    border-radius: 5px;
}

.PromoterImageHeader {
    /*position: relative;*/
    padding-bottom: 20.58%;
    height: 0;
    background-size: 100%;
}

.PromoterImageDoubleHeader {
    /*position: relative;*/
    padding-bottom: 41.16%;
    height: 0;
    background-size: 100%;
}

.PromoterImageLogo {

}

.PromoterImageCorner {
    position: relative;
    left: 100%;
    top: 0;
}

.PromoterActionButton, .PromoterActionButton:hover, .PromoterActionButton:visited {
    display: inline-block;
    text-align: center;
    border: none;
    /*padding: 20px 30px 20px 30px;*/
    /*background: #42abc2;*/
    vertical-align: top;
    color: white !important;
    margin-right: 10px;
    margin-bottom: 10px;
    font-size: 14px;
    height: 50px;
    line-height: 50px;
    text-decoration: none;
}

.LocationImage {
}

.BarqCategory {
    margin-left: 20px;
}

.BarqCategory .BarqCategoryElement {
    margin-bottom: 5px;

    padding: 6px 10px 6px 10px;
    cursor: pointer !important;
    border: solid 1px;
    border-radius: 6px;
}

.BarqCategory div {
    display: inline-block;
}

.BarqCategory div.emptyChildren {
    /*margin-left: 10px;*/
    clear: both;
}
.BarqCategory div.children {
    clear: both;
}


.BarqCategorySelected {
    background: #42abc2;
    color: white;
    border-radius: 6px;

}

.BarqCategoryNotSelected {
}

.BarqCategoryTag {
    margin: 0 10px 10px 10px;
    padding: 8px;
    /*background: green;*/
    font-weight: normal;
    border: solid 1px;
    border-radius: 6px;
    display: inline-block;
}

.BarqTag {
    margin-left: 20px;
    margin-top: 10px;
    padding: 6px 10px 6px 10px;
    /*background: green;*/
    font-weight: normal;
    border: solid 1px;
    border-radius: 6px;
    display: inline-block;
    cursor: pointer;
}





@media screen  and (max-width: 499px) {

    .PromoterImageLogo {
        position: relative;
        left: 10px;
        top: 10px;
        width: 40px;
        height: 40px;
        background-size: 40px 40px;
        border-radius: 21px;
        border: solid 2px #ffffff;

    }


    .PromoterImageCorner {
        margin-left: -75px;
        margin-top: -60px;
        width: 75px;
        height: 43px;
        background-size: 75px 43px;
    }

    .LocationImage {
        margin: 10px 8px 0 8px;
        width: calc(50% - 16px);
    }


}

@media screen and (min-width: 500px) and (max-width: 767px) {


    .PromoterImageLogo {
        position: relative;
        left: 20px;
        top: 20px;
        width: 60px;
        height: 60px;
        background-size: 60px 60px;
        border-radius: 32px;
        border: solid 4px #ffffff;

    }


    .PromoterImageCorner {
        margin-left: -75px;
        margin-top: -60px;
        width: 75px;
        height: 43px;
        background-size: 75px 43px;
    }

    .LocationImage {
        margin: 10px 8px 0 8px;
        width: calc(25% - 16px);
    }

}


@media screen  and (min-width: 768px) and (max-width: 1401px) {


    .PromoterImageLogo {
        position: relative;
        left: 20px;
        top: 20px;
        width: 60px;
        height: 60px;
        background-size: 60px 60px;
        border-radius: 32px;
        border: solid 2px #ffffff;
    }


    .PromoterImageCorner {
        margin-left: -75px;
        margin-top: -60px;
        width: 75px;
        height: 43px;
        background-size: 75px 43px;
    }

    .LocationImage {
        margin: 10px 8px 0 8px;
        width: calc(25% - 16px);
    }

}


@media screen  and (min-width: 1402px) and (max-width: 1785px) {


    .PromoterImageLogo {
        position: relative;
        left: 50px;
        top: 50px;
        width: 120px;
        height: 120px;
        background-size: 120px 120px;
        border-radius: 62px;
        border: solid 4px #ffffff;

    }


    .PromoterImageCorner {
        position: relative;
        left: 100%;
        top: 0;
        margin-left: -150px;
        margin-top: -120px;
        width: 150px;
        height: 87px;
        background-size: 150px 87px;
    }

    .LocationImage {
        margin: 10px 8px 0 8px;
        width: calc(25% - 16px);
    }

}


@media screen  and (min-width: 1786px) {

    .PromoterImageLogo {
        position: relative;
        left: 50px;
        top: 50px;
        width: 120px;
        height: 120px;
        background-size: 120px 120px;
        border-radius: 62px;
        border: solid 4px #ffffff;
    }


    .PromoterImageCorner {
        position: relative;
        left: 100%;
        top: 0;
        margin-left: -150px;
        margin-top: -120px;
        width: 150px;
        height: 87px;
        background-size: 150px 87px;
    }

    .LocationImage {
        margin: 10px 8px 0 8px;
        width: calc(25% - 16px);
    }


}

