.ErrorContainer {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.ErrorContainer .Error {
    position: fixed;
    display: inline-block;
}

.ErrorContainer .Error .Ooops {
    width: 100%;
    /*font-size: 156px;*/
    text-align: center !important;
}

.ErrorContainer .Error .Title {
    display: block;
    width: 100%;
    /*font-size: 32px;*/
    text-align: center !important;
}

.ErrorContainer .Error .Message {
    text-align: center !important;
}


.ErrorContainer .Large {
    position: fixed;
    left: 50px;
    top: 100%;
    font-weight: bold;
    color: #E5517B;
}

@media screen  and (max-width: 499px) {


    .ErrorContainer .Error {
        padding: 20px;
        left: 0;
        display: inline-block;
        width: calc(100% - 40px);
        top: 0;
        border-radius: 40px;
    }

    .ErrorContainer .Error .Ooops {
        font-size: 46px;
    }

    .ErrorContainer .Error .Title {
        font-size: 24px;
    }

    .ErrorContainer .Large {
        margin-top: -280px;
        font-size: 80px;
    }

    .ErrorImage {
        position: fixed;
        left: 100%;
        top: 100%;
        margin-left: -350px;
        margin-top: -320px;
        width: 400px;
        height: 300px;
        background-image: url("/ErrorImage.png");
        background-size: 400px 300px;
    }

}

@media screen and (min-width: 500px) and (max-width: 1023px) {


    .ErrorContainer .Error {
        left: 50px;
        width: 50%;
        top: 50%;
        margin-top: -300px;
        padding: 40px;
    }


    .ErrorContainer .Error .Ooops {
        font-size: 86px;
    }

    .ErrorContainer .Error .Title {
        font-size: 24px;
    }

    .ErrorContainer .Large {
        margin-top: -280px;
        font-size: 130px;
    }

    .ErrorImage {
        position: fixed;
        left: 100%;
        top: 100%;
        margin-left: -350px;
        margin-top: -320px;
        width: 400px;
        height: 300px;
        background-image: url("/ErrorImage.png");
        background-size: 400px 300px;
    }

}


@media screen  and (min-width: 1024px) and (max-width: 1401px) {


    .ErrorContainer .Error {
        left: 50px;
        width: 50%;
        top: 50%;
        margin-top: -300px;
        padding: 40px;
    }


    .ErrorContainer .Error .Ooops {
        font-size: 106px;
    }

    .ErrorContainer .Error .Title {
        font-size: 32px;
    }

    .ErrorContainer .Large {
        margin-top: -350px;
        font-size: 230px;
    }

    .ErrorImage {
        position: fixed;
        left: 100%;
        top: 100%;
        margin-left: -578px;
        margin-top: -446px;
        width: 528px;
        height: 396px;
        background-image: url("/ErrorImage.png");
        background-size: 528px 396px;
    }

}


@media screen  and (min-width: 1402px) and (max-width: 1785px) {


    .ErrorContainer .Error {
        left: 50px;
        width: 50%;
        top: 50%;
        margin-top: -300px;
        padding: 40px;
    }


    .ErrorContainer .Error .Ooops {
        font-size: 156px;
    }

    .ErrorContainer .Error .Title {
        font-size: 46px;
    }

    .ErrorContainer .Message {
        font-size: 34px;
    }


    .ErrorContainer .Large {
        margin-top: -440px;
        font-weight: bold;
        font-size: 350px;
    }

    .ErrorContainer .ErrorImage {
        position: fixed;
        left: 100%;
        top: 100%;
        margin-left: -660px;
        margin-top: -500px;
        width: 640px;
        height: 480px;
        background-image: url("/ErrorImage.png");
        background-size: 640px 480px;
    }


}


@media screen  and (min-width: 1786px) {


    .ErrorContainer .Error {
        left: 50%;
        width: 1000px;
        top: 50%;
        margin-left: -512px;
        margin-top: -300px;
        padding: 40px;
    }


    .ErrorContainer .Error .Ooops {
        font-size: 156px;
    }

    .ErrorContainer .Error .Title {
        font-size: 46px;
    }

    .ErrorContainer .Large {
        margin-top: -490px;
        font-weight: bold;
        font-size: 400px;
    }

    .ErrorContainer .Message {
        font-size: 34px;
    }

    .ErrorImage {
        position: fixed;
        left: 100%;
        top: 100%;
        margin-left: -850px;
        margin-top: -650px;
        width: 800px;
        height: 600px;
        background-image: url("/ErrorImage.png");
    }


}
