.BarqboardBarqListContainer {
}

.BarqboardBarqListContainerLoading {
    background: url("/barqme-logo-loading.png") center center no-repeat;
}


.BarqboardBarqList {
    display: grid;
    column-gap: 10px;
    width: calc(100% - 2px);
    vertical-align: top;
}

@media screen  and  (max-width: 767px) {

    .BarqboardBarqListContainer {
        width: 100%;
        overflow-x: scroll;
        padding-right: 15px;
        height: auto;
        padding-bottom: 5px;
    }

    .BarqboardBarqList {
        display: inline-block;
        white-space: nowrap;
        width: auto;
        /*height: 214px;*/
    }

}

@media screen  and (min-width: 768px)  and (max-width: 1023px) {

    .BarqboardBarqListContainer {
        overflow-x: scroll;
        width: 100%;

    }

    .BarqboardBarqList {
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    .BarqboardBarqList .BarqItemCard {
        display: none;
    }

    .BarqboardBarqList .BarqItemCard:nth-child(-n+4) {
        display: inline-block;
    }
}


@media screen  and (min-width: 1024px)  and (max-width: 1535px) {

    .BarqboardBarqListContainer {
        overflow-x: scroll;
        width: 100%;

    }

    .BarqboardBarqList {
        display: grid;
        grid-template-columns: repeat(5, minmax(0, 1fr));
    }

    .BarqboardBarqList .BarqItemCard {
        display: none;
    }

    .BarqboardBarqList .BarqItemCard:nth-child(-n+5) {
        display: inline-block;
    }
}

@media screen  and (min-width: 1536px) {

    .BarqboardBarqListContainer {
        overflow-x: scroll;
        width: 100%;

    }

    .BarqboardBarqList {
        display: grid;
        grid-template-columns: repeat(6, minmax(0, 1fr));
    }

    .BarqboardBarqList .BarqItemCard {
        display: none;
    }

    .BarqboardBarqList .BarqItemCard:nth-child(-n+6) {
        display: inline-block;
    }
}
