
.SearchPanelSidebar {
    vertical-align: top;
    position: relative;
    width: calc(100% - 2px);
    /*height: 410px;*/
    padding: 0;
    margin: 0;
}

.SearchPanelSidebar .SearchPanelContent {
    padding: 10px;
    border: solid 1px;
    border-radius: 10px;
    width: calc(100% - 20px);
}

.SearchPanelSidebar .SearchPanelContent .Full {
    width: 100%;
    margin-bottom: 5px;
    vertical-align: top;
}

.SearchPanelSidebar .SearchPanelContent .Left {
    width: calc(50% - 5px);
    margin-bottom: 5px;
    margin-right: 10px;
    vertical-align: top;
}

.SearchPanelSidebar .SearchPanelContent .Center {
    width: calc(50% - 5px);
    margin-bottom: 5px;
    vertical-align: top;
}

.SearchPanelSidebar .SearchPanelContent .Right {
    width: calc(50% - 5px);
    margin-bottom: 5px;
    vertical-align: top;
}


.SearchPanelSidebar .SearchPanelContent .SearchPanelContentButton {
    width: 100%;
    height: 50px;
    margin-bottom: 5px;
}

