.InitialTagOffers {
    display: grid !important;
    column-gap: 10px;
}

.InitialTagOffers .ImageWithTitleDisplay {
    display: none;
}

@media screen  and  (max-width: 499px) {
    .InitialTagOffers {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    .InitialTagOffers .ImageWithTitleDisplay:nth-child(-n+12) {
        display: inline-block !important;
    }
}

@media screen  and (min-width: 500px) and (max-width: 767px) {

    .InitialTagOffers {
        grid-template-columns: repeat(5, minmax(0, 1fr));
    }

    .InitialTagOffers .ImageWithTitleDisplay:nth-child(-n+12) {
        display: inline-block !important;
    }

}

@media screen  and (min-width: 768px) and (max-width: 1023px) {


    .InitialTagOffers {
        grid-template-columns: repeat(6, minmax(0, 1fr));
    }

    .InitialTagOffers .ImageWithTitleDisplay:nth-child(-n+15) {
        display: inline-block !important;
    }

}

@media screen  and (min-width: 1024px) and (max-width: 1439px) {

    .InitialTagOffers {
        grid-template-columns: repeat(8, minmax(0, 1fr));
    }

    .InitialTagOffers .ImageWithTitleDisplay:nth-child(-n+20) {
        display: inline-block !important;
    }

}

@media screen  and (min-width: 1440px) {


    .InitialTagOffers {
        grid-template-columns: repeat(10, minmax(0, 1fr));
    }

    .InitialTagOffers .ImageWithTitleDisplay:nth-child(-n+25) {
        display: inline-block !important;
    }

}
