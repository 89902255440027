.MyProfileHeader {
    margin-top: 50px;

}

.MyProfileHeader .MyProfileImage {
    vertical-align: top;
    width: 120px;
    height: 120px;
    margin-bottom: 20px;
}

.MyProfileHeader .MyProfileImage .Image {
    width: 120px;
    height: 120px;
    border-radius: 60px;
}

.MyProfileHeader .MyProfileData {
    vertical-align: top;
}

.MyProfileHeader .MyProfileData .Title {

    font-weight: 700;
    font-size: 28px;
    font-family: 'Comfortaa', sans-serif;
}

.MyProfileHeader .MyProfileData div {
    margin-bottom: 10px;
}


.MyProfileDataLink {
    display: inline-block;
    width: 800px;
    max-width: calc(100% - 30px);
    margin: 10px 0 0 0;
    border: solid 1px #42abc2;
    padding: 12px;
    text-align: center;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}


@media screen  and (max-width: 499px) {

    .MyProfileHeader .MyProfileImage {
        width: 100%;
        display: block;
        margin-bottom: 20px;
    }

    .MyProfileHeader .MyProfileData {
        width: 100%;
        display: block;
        margin-left: 0;
    }

    .MyProfileHeader .MyProfileData button {
        width: calc(100% - 0px);
    }

    .MyProfileHeader .MyProfileImage .Image {
        position: relative;
        left: 50%;
        margin-left: -60px;
    }
}


@media screen  and (min-width: 500px) {

    .MyProfileHeader .MyProfileImage {
        display: inline-block;
        margin-bottom: 0;
    }

    .MyProfileHeader .MyProfileData {
        display: inline-block;
        margin-left: 20px;
    }

}