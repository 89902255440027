.PagedButton {
    width: 35px;
    height: 35px;
    border: solid 1px;
    margin: 2px;
    border-radius: 4px;
}

.PagedSelect {
    margin: 2px;
    height: 35px;
    width: 150px;
    border: solid 1px;
    border-radius: 4px;
    text-align: center;
    text-align-last: center;
    font-size: 12px;
}