
 .BadgeImage  {
    display: block;
    background-size: 100%;
    margin-right: 10px;
}

.BarqBadgesListSize {
    margin-top: 0px;
    margin-left: 0px;
}

.BarqBadgesListSize .BadgeImage  {
    width: 30px;
    height: 30px;
}


.BarqBadgesNormalSize {
    margin-top: 10px;
    margin-left: 10px;
}

.BarqBadgesNormalSize .BadgeImage  {
    width: 30px;
    height: 30px;
}


.BarqBadgesLargeSize {
    margin-top: 20px;
    margin-left: 20px;
}


.BarqBadgesLargeSize .BadgeImage {
    width: 50px !important;
    height: 50px !important;
}

 .BarqBadgesGrandeSize {
     margin-top: 20px;
     margin-left: 20px;
 }


 .BarqBadgesGrandeSize .BadgeImage {
     width: 80px !important;
     height: 80px !important;
 }
